import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import FlightIcon from '@mui/icons-material/Flight';
import PersonIcon from '@mui/icons-material/Person';
import { getToken } from '../utils/auth';
import Collapse from '@mui/material/Collapse';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Grid from '@mui/material/Grid';
import EmailIcon from '@mui/icons-material/Email';
import Link from '@mui/material/Link';
import { styled, keyframes } from '@mui/material/styles';
import { alpha } from '@mui/material/styles';
import CheckIcon from '@mui/icons-material/Check';
import Modal from '@mui/material/Modal';
import Tooltip from '@mui/material/Tooltip';
import TablePagination from '@mui/material/TablePagination';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoIcon from '@mui/icons-material/Info';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Snackbar from '@mui/material/Snackbar';

const fieldOrder = [
  'user_id',
  'first_name',
  'last_name',
  'email',
  'plan',
  'created_at'
];

const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: '#ffffff',
  borderRadius: theme.shape.borderRadius * 2,
  boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
  overflow: 'hidden',
  border: '1px solid #e0e0e0',
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  background: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  padding: theme.spacing(2, 3),
  borderTopLeftRadius: theme.shape.borderRadius * 2,
  borderTopRightRadius: theme.shape.borderRadius * 2,
  fontWeight: 700,
  letterSpacing: '1px',
  textTransform: 'uppercase',
  fontSize: '1rem',
}));

const SectionContent = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: theme.palette.background.paper,
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

const InfoGrid = styled(Grid)(({ theme }) => ({
  '& > .MuiGrid-item': {
    borderBottom: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const InfoLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontWeight: 600,
  fontSize: '0.75rem',
  textTransform: 'uppercase',
  letterSpacing: '0.5px',
  marginBottom: theme.spacing(0.5),
}));

const InfoValue = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontWeight: 700,
  fontSize: '1rem',
  letterSpacing: '0.5px',
}));

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  '& .MuiAccordionSummary-expandIconWrapper': {
    color: theme.palette.primary.contrastText,
  },
}));

const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.main,
  textDecoration: 'underline',
  '&:hover': {
    textDecoration: 'underline',
  },
}));

function Row(props) {
  const { row, renderOrderedRow } = props;
  const [open, setOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => setModalOpen(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleCopyClick = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      setSnackbarOpen(true);
    });
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const InfoItem = ({ label, value, multiline, action, tooltip, link, copyable }) => (
    <Grid item xs={12} sm={6} md={4}>
      <InfoLabel>{label}</InfoLabel>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        {multiline ? (
          <List dense disablePadding>
            {Array.isArray(value) && value.map((item, index) => (
              <ListItem key={index} disablePadding>
                <InfoValue>{item}</InfoValue>
              </ListItem>
            ))}
          </List>
        ) : link ? (
          <StyledLink href={link} target="_blank" rel="noopener noreferrer">
            <InfoValue>{value || 'N/A'}</InfoValue>
          </StyledLink>
        ) : (
          <InfoValue>{value || 'N/A'}</InfoValue>
        )}
        {copyable && (
          <IconButton onClick={() => handleCopyClick(link)} size="small">
            <ContentCopyIcon fontSize="small" />
          </IconButton>
        )}
        {action}
        {tooltip && (
          <Tooltip title={tooltip} arrow>
            <InfoIcon fontSize="small" color="action" />
          </Tooltip>
        )}
      </Box>
    </Grid>
  );

  const handleResetPassword = () => {
    const token = getToken();
    fetch('https://dev-api.withautopilot.com/admin_portal/send_reset_password_email', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'token': token
      },
      body: JSON.stringify({ user_id: row.user_id })
    })
    .then(response => response.json())
    .then(data => {
      if (data.success) {
        console.log("Reset password email sent successfully");
        alert("Reset password email sent successfully");
        setAlertMessage({ severity: "success", message: "Reset password email sent successfully" });
      } else {
        console.error("Failed to send reset password email");
        setAlertMessage({ severity: "error", message: "An error occurred. Please contact sam@withautopilot.com to get this email sent out to the user." });
      }
    })
    .catch(error => {
      console.error("Error sending reset password email:", error);
      setAlertMessage({ severity: "error", message: "An error occurred. Please contact sam@withautopilot.com to get this email sent out to the user." });
    })
    .finally(() => {
      handleModalClose();
    });
  };

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const totalReferralsSaved = row.referral_made_info
    ? row.referral_made_info.reduce((sum, referral) => sum + referral.total_saved_by_referred_user, 0)
    : 0;

  const referralTooltip = row.referral_info
    ? `Referral Code: ${row.referral_info.referrer_referral_code}
       Referral Date: ${row.referral_info.referral_date}
       Referrer User ID: ${row.referral_info.referrer_user_id}`
    : '';

  const referralLink = row.referral_info?.user_referral_code 
    ? `https://withautopilot.com/?autopilot_referral=${row.referral_info.user_referral_code}`
    : '';

  return (
    <>
      <TableRow
        sx={{
          '& > *': { borderBottom: 'unset' },
          cursor: 'pointer',
          '&:hover': { backgroundColor: (theme) => alpha(theme.palette.primary.light, 0.1) },
        }}
        onClick={() => setOpen(!open)}
      >
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              setOpen(!open);
            }}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {renderOrderedRow(row)}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={fieldOrder.length + 2}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 2 }}>
              <Accordion defaultExpanded>
                <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="h6">User Info</Typography>
                </StyledAccordionSummary>
                <AccordionDetails>
                  <InfoGrid container spacing={3}>
                    <InfoItem 
                      label="Signin Method" 
                      value={row.sso_enabled ? 'SSO' : 'Username/Password'}
                      action={
                        <Button
                          variant="outlined"
                          size="small"
                          startIcon={<EmailIcon />}
                          onClick={handleModalOpen}
                        >
                          Send Reset PW Email
                        </Button>
                      }
                    />
                    <InfoItem 
                      label="Stripe Customer ID" 
                      value={
                        row.billing_info?.stripe_customer_id ? (
                          <Link 
                            href={`https://dashboard.stripe.com/customers/${row.billing_info.stripe_customer_id}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {row.billing_info.stripe_customer_id}
                          </Link>
                        ) : 'N/A'
                      }
                    />
                    <InfoItem label="User ID" value={row.user_id} />
                    <InfoItem label="Plan" value={row.plan} />
                    <InfoItem label="Permissions" value={row.permissions} />
                    <InfoItem label="Created At" value={row.created_at} />
                    <InfoItem label="Linked Emails" value={row.primary_emails} multiline />
                    <InfoItem 
                      label="Referred By" 
                      value={
                        row.referral_info
                          ? `${row.referral_info.referrer_first_name} ${row.referral_info.referrer_last_name}`
                          : 'N/A'
                      }
                      tooltip={referralTooltip}
                    />
                    <InfoItem 
                      label="User's Personalized Referral Code" 
                      value={row.referral_info?.user_referral_code || 'N/A'}
                      link={referralLink}
                      copyable
                    />
                  </InfoGrid>
                </AccordionDetails>
              </Accordion>

              {row.referral_made_info && row.referral_made_info.length > 0 && (
                <Accordion>
                  <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h6">Referrals Made</Typography>
                  </StyledAccordionSummary>
                  <AccordionDetails>
                    <Box sx={{ mb: 2 }}>
                      <Typography variant="subtitle1" fontWeight="bold">
                        Total Referrals Made: {row.referral_made_info.length}
                      </Typography>
                      <Typography variant="subtitle1" fontWeight="bold">
                        Total Amount Saved by Referrals: ${totalReferralsSaved.toFixed(2)}
                      </Typography>
                    </Box>
                    <TableContainer>
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell>User ID</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Referral Date</TableCell>
                            <TableCell>Total Amount Saved</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {row.referral_made_info
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((referral, index) => (
                            <TableRow key={index}>
                              <TableCell>{referral.referred_user_id}</TableCell>
                              <TableCell>{referral.referred_user_name}</TableCell>
                              <TableCell>{referral.referral_date}</TableCell>
                              <TableCell>${referral.total_saved_by_referred_user.toFixed(2)}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25]}
                      component="div"
                      count={row.referral_made_info.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </AccordionDetails>
                </Accordion>
              )}

              <Accordion>
                <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="h6">Reservation Info</Typography>
                </StyledAccordionSummary>
                <AccordionDetails>
                  <InfoGrid container spacing={3}>
                    <InfoItem 
                      label="Total Reservations" 
                      value={row.reservations.no_reservations} 
                    />
                    <InfoItem 
                      label="Upcoming Reservations" 
                      value={row.reservations.no_upcoming_reservations} 
                    />
                    <InfoItem 
                      label="Last Reservation Imported" 
                      value={row.reservations.last_reservation_imported_relative} 
                    />
                  </InfoGrid>
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="h6">Savings & Credits</Typography>
                </StyledAccordionSummary>
                <AccordionDetails>
                  <InfoGrid container spacing={3} sx={{ mb: 3 }}>
                    <InfoItem 
                      label="Total Savings" 
                      value={`$${row.savings_credits.total_saved_amount.toFixed(2)}`} 
                    />
                    <InfoItem 
                      label="Total Revenue" 
                      value={`$${row.billing_info?.total_revenue?.toFixed(2) || '0.00'}`} 
                    />
                    <InfoItem 
                      label="Number of Savings/Credits" 
                      value={row.savings_credits.no_savings_credits} 
                    />
                  </InfoGrid>
                  {row.savings_credits.no_savings_credits > 0 && (
                    <TableContainer component={Paper} elevation={2} sx={{ borderRadius: 2, overflow: 'hidden' }}>
                      <Table size="small" aria-label="savings and credits">
                        <TableHead>
                          <TableRow sx={{ backgroundColor: (theme) => theme.palette.primary.main }}>
                            <TableCell sx={{ color: 'white', fontWeight: 700 }}>Airline</TableCell>
                            <TableCell sx={{ color: 'white', fontWeight: 700 }}>Amount</TableCell>
                            <TableCell sx={{ color: 'white', fontWeight: 700 }}>Issue Date</TableCell>
                            <TableCell sx={{ color: 'white', fontWeight: 700 }}>Expiration Date</TableCell>
                            <TableCell sx={{ color: 'white', fontWeight: 700 }}>Reference Number</TableCell>
                            <TableCell sx={{ color: 'white', fontWeight: 700 }}>Type</TableCell>
                            <TableCell sx={{ color: 'white', fontWeight: 700 }}>Passenger Name</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {Object.values(row.savings_credits.savings_credits_info).flatMap((credit) =>
                            credit.voucher_credits.map((voucher, index) => (
                              <TableRow key={`${credit.reservation_id}-${index}`} sx={{ 
                                '&:nth-of-type(odd)': { backgroundColor: (theme) => alpha(theme.palette.action.hover, 0.05) },
                              }}>
                                <TableCell>{credit.airline}</TableCell>
                                <TableCell>${voucher.amount.toFixed(2)}</TableCell>
                                <TableCell>{voucher.issue_date}</TableCell>
                                <TableCell>{voucher.expiration_date}</TableCell>
                                <TableCell>
                                  <Tooltip title={`PNR: ${credit.pnr}`} arrow>
                                    <span>{voucher.reference_number}</span>
                                  </Tooltip>
                                </TableCell>
                                <TableCell>{voucher.credit_type}</TableCell>
                                <TableCell>{voucher.passenger_name}</TableCell>
                              </TableRow>
                            ))
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}
                </AccordionDetails>
              </Accordion>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <Modal
        open={modalOpen}
        onClose={handleModalClose}
        aria-labelledby="reset-password-modal-title"
        aria-describedby="reset-password-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="reset-password-modal-title" variant="h6" component="h2">
            Confirm Password Reset
          </Typography>
          <Typography id="reset-password-modal-description" sx={{ mt: 2 }}>
            Are you sure you want to reset the password for "{row.first_name} {row.last_name}"? By sending them a reset password email, they will be logged out of all sessions, and will not be able to log back in until their password has been reset. The reset password email will be sent to: {row.email}.
          </Typography>
          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
            <Button onClick={handleModalClose}>Cancel</Button>
            <Button onClick={handleResetPassword} variant="contained">Confirm</Button>
          </Box>
        </Box>
      </Modal>
      {alertMessage && (
        <Alert 
          icon={alertMessage.severity === "success" ? <CheckIcon fontSize="inherit" /> : undefined}
          severity={alertMessage.severity}
          onClose={() => setAlertMessage(null)}
          sx={{ mt: 2 }}
        >
          {alertMessage.message}
        </Alert>
      )}
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message="Referral link copied to clipboard"
      />
    </>
  );
}

function UserTable() {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchCriteria, setSearchCriteria] = useState({
    user_id: '',
    first_name: '',
    last_name: '',
    email: '',
    first_last_name: '',
    created_at_date: ''
  });
  const [results, setResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = useCallback(async (e, userIdOverride = null) => {
    if (e) e.preventDefault();
    setIsLoading(true);
    setError(null);

    const payload = Object.entries(searchCriteria).reduce((acc, [key, value]) => {
      if (value) acc[key] = value;
      return acc;
    }, {});

    if (userIdOverride) {
      payload.user_id = userIdOverride;
    }

    if (Object.values(payload).every(x => x === '')) {
      setError("Please enter at least one search criterion");
      setIsLoading(false);
      return;
    }

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("token", getToken());

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify(payload),
      redirect: 'follow'
    };

    try {
      //const response = await fetch("http://127.0.0.1:8080/admin_portal/get_users", requestOptions);
      const response = await fetch("https://dev-api.withautopilot.com/admin_portal/get_users", requestOptions);
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      console.log(result);
      setResults(Array.isArray(result) ? result : [result]);
    } catch (e) {
      console.error("There was a problem with the fetch operation:", e);
      setError("An error occurred while fetching the data. Please try again.");
    } finally {
      setIsLoading(false);
    }
  }, [searchCriteria]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const userIdFromUrl = params.get('user_id');
    if (userIdFromUrl) {
      setSearchCriteria(prev => ({ ...prev, user_id: userIdFromUrl }));
      handleSubmit(null, userIdFromUrl);
      // Remove the query parameter from the URL
      navigate('/users', { replace: true });
    }
  }, [location, navigate, handleSubmit]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSearchCriteria(prev => ({ ...prev, [name]: value }));
  };

  const handleReservationLookup = (userId) => {
    navigate(`/reservation?user_id=${userId}`);
  };

  const handlePassengerLookup = (userId) => {
    navigate(`/passenger?user_id=${userId}`);
  };

  const renderOrderedRow = (row) => {
    return [
      ...fieldOrder.map((field) => (
        <TableCell key={field}>{row[field] || ''}</TableCell>
      )),
      <TableCell key="actions">
        <IconButton onClick={() => handleReservationLookup(row.user_id)} aria-label="Reservation lookup">
          <FlightIcon />
        </IconButton>
        <IconButton onClick={() => handlePassengerLookup(row.user_id)} aria-label="Passenger lookup">
          <PersonIcon />
        </IconButton>
      </TableCell>
    ];
  };

  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, marginBottom: 2 }}>
          {Object.keys(searchCriteria).map((key) => (
            <TextField
              key={key}
              label={key.replace(/_/g, ' ')}
              name={key}
              value={searchCriteria[key]}
              onChange={handleInputChange}
              variant="outlined"
              size="small"
            />
          ))}
        </Box>
        <Button type="submit" variant="contained" disabled={isLoading}>
          {isLoading ? <CircularProgress size={24} /> : "Search"}
        </Button>
      </form>

      {error && <Alert severity="error" sx={{ mt: 2 }}>{error}</Alert>}

      {results.length > 0 && (
        <TableContainer component={Paper} sx={{ marginTop: 2 }}>
          <Table sx={{ minWidth: 650 }} aria-label="user results table">
            <TableHead>
              <TableRow>
                <TableCell />
                {fieldOrder.map((field) => (
                  <TableCell key={field}>{field.replace(/_/g, ' ')}</TableCell>
                ))}
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {results.map((row, index) => (
                <Row key={index} row={row} renderOrderedRow={renderOrderedRow} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
}

export default UserTable;
