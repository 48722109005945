import React, { useState } from 'react';
import {
  Dialog, DialogTitle, DialogContent, DialogActions,
  Button, CircularProgress, Typography, Box, Stack,
  Accordion, AccordionSummary, AccordionDetails,
  Switch, useTheme
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Cookies from 'js-cookie';

function TrackingDialog({ open, onClose, reservationData, onRefresh }) {
  const theme = useTheme();
  const [isUpdatingStatus, setIsUpdatingStatus] = useState(false);
  const [trackingStatuses, setTrackingStatuses] = useState({
    is_active: reservationData?.is_active || false,
    is_archived: reservationData?.is_archived || false,
    is_auto_rebook_enabled: reservationData?.is_auto_rebook_enabled || false,
    is_tracked: reservationData?.is_tracked || false,
    is_upcoming: reservationData?.is_upcoming || false,
    is_visible: reservationData?.is_visible || false,
    is_award: reservationData?.is_award || false,
    is_basic_economy: reservationData?.is_basic_economy || false,
    is_elite_upgrade: reservationData?.is_elite_upgrade || false,
    is_refundable: reservationData?.is_refundable || false,
    is_direct_booking: reservationData?.is_direct_booking || false,
  });

  const getWarningMessage = (key, isChecked) => {
    switch(key) {
      case 'is_award':
        return `Changing reservation ${isChecked ? 'to' : 'from'} Award is not advised and will impact how this reservation is treated. All prices will be pulled as award moving forward`;
      case 'is_basic_economy':
        return "Adding or removing the basic economy flag will let the user toggle on Tracking. It is advised that if you intend to track this reservation that you also make sure that \"Tracked\" is on and \"Active\" and \"Auto Rebook\"";
      case 'is_elite_upgrade':
        return "When an elite upgrade is on a reservation, it disables, by default, the user from tracking the reservation.";
      case 'is_refundable':
        return "This will begin to pull the refundable price for this ticket.";
      case 'is_direct_booking':
        return "This flag should be on when a booking is direct and off when a booking isn't direct. In rare cases are we able to modify bookings made with a third party.";
      default:
        return null;
    }
  };

  const handleStatusChange = (key, newValue) => {
    const warningMessage = getWarningMessage(key, newValue);
    if (warningMessage) {
      const confirmed = window.confirm(warningMessage);
      if (confirmed) {
        setTrackingStatuses(prev => ({ ...prev, [key]: newValue }));
      }
    } else {
      setTrackingStatuses(prev => ({ ...prev, [key]: newValue }));
    }
  };

  const handleUpdateTrackingStatus = async () => {
    setIsUpdatingStatus(true);
    try {
      const token = Cookies.get('token');
      const response = await fetch('https://dev-api.withautopilot.com/admin_portal/update_reservation_status', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'token': token,
        },
        body: JSON.stringify({
          reservation_id: reservationData.reservation_id,
          ...trackingStatuses
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to update tracking status');
      }

      if (typeof onRefresh === 'function') {
        onRefresh();
      }
      onClose();
    } catch (error) {
      console.error('Error updating tracking status:', error);
    } finally {
      setIsUpdatingStatus(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        elevation: 0,
        sx: {
          borderRadius: '16px',
          maxWidth: '500px'
        }
      }}
    >
      <DialogTitle sx={{ pb: 1, pt: 2.5, px: 3 }}>
        <Typography variant="h6" sx={{ 
          fontWeight: 600,
          textAlign: 'center',
          color: theme.palette.text.primary,
          fontSize: '1.125rem'
        }}>
          Edit Reservation Status
        </Typography>
      </DialogTitle>

      <DialogContent sx={{ px: 3 }}>
        <Stack spacing={2} sx={{ mt: 1 }}>
          {/* Tracking Status Accordion */}
          <Accordion
            defaultExpanded
            elevation={0}
            sx={{
              border: `1px solid ${theme.palette.divider}`,
              borderRadius: '12px !important',
              '&:before': { display: 'none' },
              '& .MuiAccordionSummary-root': {
                borderRadius: '12px',
                '&:hover': { bgcolor: 'rgba(25, 118, 210, 0.04)' }
              }
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{ px: 2 }}
            >
              <Typography variant="subtitle2" sx={{ 
                fontWeight: 600,
                color: theme.palette.primary.main,
                fontSize: '0.875rem'
              }}>
                Tracking Status
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ px: 2, pt: 0, pb: 2 }}>
              <Stack spacing={1}>
                {['active', 'tracked', 'visible', 'archived', 'upcoming', 'auto_rebook_enabled'].map((key) => (
                  <Box
                    key={key}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      p: 1.5,
                      borderRadius: '12px',
                      bgcolor: trackingStatuses[`is_${key}`] ? 'primary.50' : 'background.default',
                      border: `1px solid ${trackingStatuses[`is_${key}`] ? theme.palette.primary.main : theme.palette.divider}`,
                      transition: 'all 0.2s ease-in-out'
                    }}
                  >
                    <Typography sx={{ 
                      fontSize: '0.875rem',
                      color: trackingStatuses[`is_${key}`] ? 'primary.main' : 'text.secondary',
                      fontWeight: trackingStatuses[`is_${key}`] ? 500 : 400
                    }}>
                      {key.split('_').map(word => 
                        word.charAt(0).toUpperCase() + word.slice(1)
                      ).join(' ')}
                    </Typography>
                    <Switch
                      checked={trackingStatuses[`is_${key}`]}
                      onChange={(e) => handleStatusChange(`is_${key}`, e.target.checked)}
                      color="primary"
                      size="small"
                    />
                  </Box>
                ))}
              </Stack>
            </AccordionDetails>
          </Accordion>

          {/* Reservation Type Accordion */}
          <Accordion
            defaultExpanded
            elevation={0}
            sx={{
              border: `1px solid ${theme.palette.divider}`,
              borderRadius: '12px !important',
              '&:before': { display: 'none' },
              '& .MuiAccordionSummary-root': {
                borderRadius: '12px',
                '&:hover': { bgcolor: 'rgba(211, 47, 47, 0.04)' }
              }
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{ px: 2 }}
            >
              <Typography variant="subtitle2" sx={{ 
                fontWeight: 600,
                color: theme.palette.error.main,
                fontSize: '0.875rem'
              }}>
                Reservation Type
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ px: 2, pt: 0, pb: 2 }}>
              <Stack spacing={1}>
                {['award', 'basic_economy', 'elite_upgrade', 'refundable', 'direct_booking'].map((key) => (
                  <Box
                    key={key}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      p: 1.5,
                      borderRadius: '12px',
                      bgcolor: trackingStatuses[`is_${key}`] ? 'error.50' : 'background.default',
                      border: `1px solid ${trackingStatuses[`is_${key}`] ? theme.palette.error.main : theme.palette.divider}`,
                      transition: 'all 0.2s ease-in-out'
                    }}
                  >
                    <Typography sx={{ 
                      fontSize: '0.875rem',
                      color: trackingStatuses[`is_${key}`] ? 'error.main' : 'text.secondary',
                      fontWeight: trackingStatuses[`is_${key}`] ? 500 : 400
                    }}>
                      {key.split('_').map(word => 
                        word.charAt(0).toUpperCase() + word.slice(1)
                      ).join(' ')}
                    </Typography>
                    <Switch
                      checked={trackingStatuses[`is_${key}`]}
                      onChange={(e) => handleStatusChange(`is_${key}`, e.target.checked)}
                      color="error"
                      size="small"
                    />
                  </Box>
                ))}
              </Stack>
            </AccordionDetails>
          </Accordion>
        </Stack>
      </DialogContent>

      <DialogActions sx={{ px: 3, pb: 3, pt: 2 }}>
        <Button 
          onClick={onClose}
          sx={{ 
            color: theme.palette.text.secondary,
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.04)'
            }
          }}
        >
          Cancel
        </Button>
        <Button 
          onClick={handleUpdateTrackingStatus}
          disabled={isUpdatingStatus}
          variant="contained"
          sx={{ 
            px: 3,
            borderRadius: '8px',
            boxShadow: 'none',
            '&:hover': {
              boxShadow: 'none'
            }
          }}
        >
          {isUpdatingStatus ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            'Save Changes'
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default TrackingDialog; 