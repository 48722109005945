import dayjs from 'dayjs';
import { parseISO, format } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

export const formatDateTime = (dateTimeString) => {
  if (!dateTimeString) return 'N/A';
  try {
    const date = parseISO(dateTimeString);
    return format(date, 'MMM d, yyyy h:mm a');
  } catch (error) {
    console.error('Error parsing date:', error);
    return 'Invalid Date';
  }
};

export const formatDate = (dateString) => {
  return dateString ? dayjs(dateString).format('MMMM D, YYYY') : 'N/A';
};

export const formatCurrency = (value, isAward = false, currencySymbol = '$') => {
  const number = parseFloat(value);
  if (isNaN(number)) {
    return isAward ? '0 Miles' : `${currencySymbol}0.00`;
  }
  
  if (isAward) {
    return `${number.toLocaleString()} Miles`;
  } else {
    return `${currencySymbol}${number.toFixed(2)}`;
  }
};

export const formatToEST = (dateTimeString) => {
  return dateTimeString ? 
    formatInTimeZone(
      parseISO(dateTimeString), 
      'America/New_York', 
      'MMMM d, yyyy h:mm a z'
    ) : 'N/A';
};

export const formatImportMethod = (method) => {
  if (!method) return 'N/A';
  return method.charAt(0).toUpperCase() + method.slice(1).toLowerCase();
}; 