import React, { useState, useMemo } from 'react';
import {
  Accordion, AccordionSummary, AccordionDetails, Typography,
  Box, Card, CardContent, Stack, Tabs, Tab,
  Table, TableContainer, TableHead, TableRow,
  TableCell, TableBody, Chip, FormGroup, 
  FormControlLabel, Checkbox, Paper, Button, CircularProgress, Snackbar, Alert
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { LineChart } from '@mui/x-charts/LineChart';
import { formatDateTime } from '../utils/formatters';
import dayjs from 'dayjs';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import { DataGrid } from '@mui/x-data-grid';
import RefreshIcon from '@mui/icons-material/Refresh';
import AddIcon from '@mui/icons-material/Add';
import axios from 'axios';

function UpgradeOffersSection({ reservationData, reservationId }) {
  console.log('UpgradeOffersSection props:', { reservationData, reservationId });

  const [selectedTab, setSelectedTab] = useState(0);
  const [priceType, setPriceType] = useState(['cash', 'miles']); // Both selected by default
  const [selectedCabins, setSelectedCabins] = useState({}); // Will be initialized with all cabins
  const [isInitializing, setIsInitializing] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  // Memoize segments to prevent unnecessary re-renders
  const segments = useMemo(() => 
    reservationData?.upgrade_offer_info?.segments || [],
    [reservationData?.upgrade_offer_info?.segments]
  );

  // Initialize selected cabins when tab changes
  React.useEffect(() => {
    if (segments[selectedTab]?.upgradeable_cabins) {
      const cabins = segments[selectedTab].upgradeable_cabins;
      // Normalize cabin names and remove duplicates
      const normalizedCabins = [...new Set(cabins.map(cabin => 
        cabin === 'Economy Preferred Seat' ? 'Preferred Seat' : cabin
      ))];
      
      setSelectedCabins(
        normalizedCabins.reduce((acc, cabin) => ({ ...acc, [cabin]: true }), {})
      );
    }
  }, [selectedTab, segments]);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handlePriceTypeChange = (type) => {
    setPriceType(prev => {
      const newTypes = prev.includes(type)
        ? prev.filter(t => t !== type)
        : [...prev, type];
      return newTypes.length ? newTypes : prev; // Prevent deselecting all
    });
  };

  const handleCabinToggle = (cabin) => {
    setSelectedCabins(prev => ({
      ...prev,
      [cabin]: !prev[cabin]
    }));
  };

  const formatRoute = (segment) => {
    const activeAlerts = segment.upgrade_alert_info?.filter(alert => alert.is_active)?.length || 0;
    
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <span>{`${segment.departure_airport} - ${segment.arrival_airport}`}</span>
        <span style={{ color: 'text.secondary' }}>{`(Segment ID: ${segment.segment_id})`}</span>
        <Chip 
          label={`${activeAlerts} ${activeAlerts === 1 ? 'Alert' : 'Alerts'}`}
          size="small"
          color="info"
          variant={activeAlerts === 0 ? "outlined" : "filled"}
          sx={{ 
            height: '20px',
            '& .MuiChip-label': {
              px: 1,
              fontSize: '0.75rem'
            },
            ...(activeAlerts === 0 && {
              backgroundColor: 'white',
              borderColor: 'info.main',
              color: 'info.main'
            })
          }}
        />
      </Box>
    );
  };

  const prepareUpgradeOfferData = (offerHistory) => {
    if (!Array.isArray(offerHistory)) return [];
    
    const dataset = [];
    
    offerHistory.forEach(offer => {
      const offerDate = new Date(offer.created_at);
      if (isNaN(offerDate.getTime())) {
        console.warn('Invalid date encountered:', offer.created_at);
        return;
      }

      // Normalize cabin name for Preferred Seat types
      let cabinName = offer.cabin_offered;
      if (cabinName === 'Economy Preferred Seat' || cabinName === 'Preferred Seat') {
        cabinName = 'Preferred Seat'; // Standardize to one name
      }

      const existingEntry = dataset.find(entry => 
        entry.date.getTime() === offerDate.getTime()
      );

      if (existingEntry) {
        if (offer.offer_type === 'cash') {
          existingEntry[`${cabinName}_price`] = offer.price || null;
        } else if (offer.offer_type === 'miles') {
          existingEntry[`${cabinName}_miles`] = offer.miles_required || null;
        }
      } else {
        const newEntry = { date: offerDate };
        if (offer.offer_type === 'cash') {
          newEntry[`${cabinName}_price`] = offer.price || null;
        } else if (offer.offer_type === 'miles') {
          newEntry[`${cabinName}_miles`] = offer.miles_required || null;
        }
        dataset.push(newEntry);
      }
    });

    return dataset.sort((a, b) => a.date - b.date);
  };

  const prepareTableData = (offerHistory) => {
    if (!Array.isArray(offerHistory)) return [];
    
    return offerHistory.map((offer, index) => {
      const isCashOffer = offer.offer_type === 'cash';
      return {
        id: index,
        cabin: offer.cabin_offered || '',
        price: isCashOffer ? offer.price : (offer.miles_required || 0),
        type: isCashOffer ? 'Cash' : 'Miles',
        dateSeen: new Date(offer.created_at).toISOString(),
      };
    });
  };

  const renderUpgradeChart = (segment) => {
    const data = prepareUpgradeOfferData(segment.upgrade_offer_history);
    // Normalize cabin names and remove duplicates
    const cabins = [...new Set(segment.upgradeable_cabins?.map(cabin => 
      cabin === 'Economy Preferred Seat' ? 'Preferred Seat' : cabin
    ) || [])];

    // Filter series based on selected price types and cabins
    const cashSeries = priceType.includes('cash') ? cabins
      .filter(cabin => selectedCabins[cabin])
      .map((cabin, i) => ({
        dataKey: `${cabin}_price`,
        label: `${cabin} (Cash)`,
        color: `hsl(${(i * 137.508) % 360}, 70%, 45%)`,
        valueFormatter: (value) => (value ? `$${value}` : 'N/A'),
        showMark: true,
        yAxisKey: 'cashAxis',
      })) : [];

    const milesSeries = priceType.includes('miles') ? cabins
      .filter(cabin => selectedCabins[cabin])
      .map((cabin, i) => ({
        dataKey: `${cabin}_miles`,
        label: `${cabin} (Miles)`,
        color: `hsl(${(i * 137.508) % 360}, 70%, 45%)`,
        style: { strokeDasharray: '5 5' },
        valueFormatter: (value) => (value ? `${value.toLocaleString()} miles` : 'N/A'),
        showMark: true,
        yAxisKey: 'milesAxis',
      })) : [];

    // Get min/max values for each axis
    const cashValues = data.flatMap(entry => 
      cabins.map(cabin => entry[`${cabin}_price`]).filter(Boolean)
    );
    const milesValues = data.flatMap(entry => 
      cabins.map(cabin => entry[`${cabin}_miles`]).filter(Boolean)
    );

    const minCash = Math.min(...cashValues);
    const maxCash = Math.max(...cashValues);
    const minMiles = Math.min(...milesValues);
    const maxMiles = Math.max(...milesValues);

    return (
      <Box sx={{ mt: 2 }}>
        <Box sx={{ 
          mb: 2, 
          display: 'flex', 
          gap: 3,
          flexWrap: 'wrap',
          alignItems: 'flex-start' 
        }}>
          {/* Price Type Selection */}
          <Paper 
            elevation={0} 
            sx={{ 
              p: 1.5,
              border: '1px solid',
              borderColor: 'divider',
              borderRadius: 1,
            }}
          >
            <Typography variant="subtitle2" gutterBottom>
              Price Type
            </Typography>
            <FormGroup row sx={{ gap: 2 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={priceType.includes('cash')}
                    onChange={() => handlePriceTypeChange('cash')}
                    size="small"
                  />
                }
                label={
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                    <AttachMoneyIcon sx={{ fontSize: 18 }} />
                    <span>Cash</span>
                  </Box>
                }
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={priceType.includes('miles')}
                    onChange={() => handlePriceTypeChange('miles')}
                    size="small"
                  />
                }
                label={
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                    <WorkspacePremiumIcon sx={{ fontSize: 18 }} />
                    <span>Miles</span>
                  </Box>
                }
              />
            </FormGroup>
          </Paper>

          {/* Cabin Selection */}
          <Paper 
            elevation={0} 
            sx={{ 
              p: 1.5,
              border: '1px solid',
              borderColor: 'divider',
              borderRadius: 1,
            }}
          >
            <Typography variant="subtitle2" gutterBottom>
              Cabins
            </Typography>
            <FormGroup row>
              {cabins.map((cabin) => (
                <FormControlLabel
                  key={cabin}
                  control={
                    <Checkbox
                      checked={selectedCabins[cabin] || false}
                      onChange={() => handleCabinToggle(cabin)}
                      size="small"
                    />
                  }
                  label={cabin}
                />
              ))}
            </FormGroup>
          </Paper>

          {/* New Action Buttons */}
          <Paper 
            elevation={0} 
            sx={{ 
              p: 1.5,
              border: '1px solid',
              borderColor: 'divider',
              borderRadius: 1,
              display: 'flex',
              flexDirection: 'column',
              gap: 1
            }}
          >
            <Typography variant="subtitle2" gutterBottom>
              Actions
            </Typography>
            <Box sx={{ display: 'flex', gap: 1 }}>
              <Button
                variant="outlined"
                size="small"
                startIcon={isInitializing ? <CircularProgress size={16} /> : <AddIcon />}
                onClick={handleInitializeOffers}
                disabled={isInitializing}
                sx={{
                  minWidth: 135, // Prevent button width from changing during loading
                  '& .MuiCircularProgress-root': {
                    marginRight: 1
                  }
                }}
              >
                {isInitializing ? 'Initializing...' : 'Initialize Offers'}
              </Button>
              <Button
                variant="outlined"
                size="small"
                startIcon={isRefreshing ? <CircularProgress size={16} /> : <RefreshIcon />}
                onClick={handleRefreshOffers}
                disabled={isRefreshing}
                sx={{
                  minWidth: 130, // Prevent button width from changing during loading
                  '& .MuiCircularProgress-root': {
                    marginRight: 1
                  }
                }}
              >
                {isRefreshing ? 'Refreshing...' : 'Refresh Offers'}
              </Button>
            </Box>
          </Paper>
        </Box>

        <LineChart
          dataset={data}
          xAxis={[{
            dataKey: 'date',
            scaleType: 'time',
            valueFormatter: (date) => {
              if (!(date instanceof Date) || isNaN(date.getTime())) {
                return 'Invalid Date';
              }
              return dayjs(date).format('MMM D, YYYY h:mm A');
            },
          }]}
          yAxis={[
            {
              id: 'cashAxis',
              scaleType: 'linear',
              valueFormatter: (value) => `$${value}`,
              label: 'Upgrade Price (USD)',
              min: Math.floor(minCash * 0.9),
              max: Math.ceil(maxCash * 1.1),
              position: 'left',
            },
            {
              id: 'milesAxis',
              scaleType: 'linear',
              valueFormatter: (value) => `${value.toLocaleString()} miles`,
              label: 'Miles Required',
              min: Math.floor(minMiles * 0.9),
              max: Math.ceil(maxMiles * 1.1),
              position: 'right',
              labelOffset: 80,
            }
          ]}
          series={[
            ...cashSeries,
            ...milesSeries.map(series => ({
              ...series,
              yAxisKey: 'milesAxis',
              curve: 'linear'
            }))
          ]}
          height={350}
          margin={{ left: 80, right: 120, top: 50, bottom: 40 }}
          sx={{
            '& .MuiChartsAxis-right .MuiChartsAxis-tickLabel': {
              transform: 'translate(-10px, 0px)'
            },
            '& .MuiChartsAxis-right .MuiChartsAxis-line': {
              stroke: '#666',
            },
            '& .MuiChartsAxis-right .MuiChartsAxis-tick': {
              stroke: '#666',
            }
          }}
          slotProps={{
            legend: {
              direction: 'row',
              position: { vertical: 'top', horizontal: 'middle' },
              padding: 20,
              itemMarkWidth: 10,
              itemMarkHeight: 2,
              markGap: 5,
              itemGap: 15,
              labelStyle: {
                fontSize: 12,
              },
            }
          }}
        />
      </Box>
    );
  };

  const renderUpgradeTable = (segment) => {
    const tableData = prepareTableData(segment.upgrade_offer_history);

    const columns = [
      { 
        field: 'cabin', 
        headerName: 'Cabin', 
        flex: 1,
        minWidth: 200,
        headerAlign: 'left',
        align: 'left',
      },
      { 
        field: 'price', 
        headerName: 'Price', 
        flex: 1,
        minWidth: 120,
        headerAlign: 'left',
        align: 'left',
        renderCell: (params) => {
          if (!params.value) return '';
          return params.row.type === 'Cash'
            ? `$${Number(params.value).toLocaleString()}`
            : `${Number(params.value).toLocaleString()} miles`;
        },
      },
      { 
        field: 'type', 
        headerName: 'Type', 
        width: 100,
        headerAlign: 'left',
        align: 'left',
        renderCell: (params) => (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
            {params.value === 'Cash' ? (
              <AttachMoneyIcon sx={{ fontSize: 16 }} />
            ) : (
              <WorkspacePremiumIcon sx={{ fontSize: 16 }} />
            )}
            {params.value}
          </Box>
        ),
      },
      { 
        field: 'dateSeen', 
        headerName: 'Date Seen', 
        flex: 1,
        minWidth: 180,
        headerAlign: 'left',
        align: 'left',
        renderCell: (params) => {
          if (!params.value) return '';
          return dayjs(params.value).format('MMM D, YYYY h:mm A');
        },
      },
    ];

    return (
      <Box sx={{ width: '100%', mt: 3 }}>
        <Typography variant="h6" gutterBottom>
          Upgrade Offer Details
        </Typography>
        <TableContainer 
          component={Paper} 
          sx={{ 
            maxHeight: 300,
            '& .MuiDataGrid-virtualScroller': {
              overflowX: 'hidden',
            },
          }}
        >
          <DataGrid
            rows={tableData}
            columns={columns}
            autoHeight={false}
            hideFooter={true}
            disableRowSelectionOnClick
            density="compact"
            sx={{
              border: 'none',
              height: 300,
              '& .MuiDataGrid-cell': {
                borderColor: 'divider',
                py: '8px',
                display: 'flex',
                alignItems: 'center',
              },
              '& .MuiDataGrid-row': {
                '&:nth-of-type(odd)': {
                  backgroundColor: 'action.hover',
                },
                '&:hover': {
                  backgroundColor: 'action.selected',
                },
              },
              '& .MuiDataGrid-columnHeaders': {
                backgroundColor: 'white',
                borderBottom: '2px solid',
                borderColor: 'divider',
                alignItems: 'center',
                '& .MuiDataGrid-columnHeaderTitle': {
                  fontWeight: 700,
                  color: 'black',
                  fontSize: '0.95rem',
                },
                '& .MuiDataGrid-sortIcon': {
                  color: 'black',
                },
                '& .MuiDataGrid-menuIcon': {
                  color: 'black',
                },
              },
            }}
          />
        </TableContainer>
      </Box>
    );
  };

  // Add these new handlers
  const handleInitializeOffers = async () => {
    setIsInitializing(true);
    try {
      // Get the current segment from the selected tab
      const currentSegment = segments[selectedTab];
      
      // Debug logging
      console.log('Current segment:', currentSegment);
      console.log('Reservation ID:', reservationId);
      
      if (!currentSegment || !reservationId) {
        console.error('Missing required data: segment or reservation ID');
        return;
      }

      // Get the segment_id from the correct property
      const segmentId = currentSegment.segment_id || currentSegment.id;

      if (!segmentId) {
        console.error('Missing segment ID in segment data');
        return;
      }

      const response = await fetch('https://dev-api.withautopilot.com/admin_portal/initialize_upgrade_offers', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'token': document.cookie.split('; ').find(row => row.startsWith('token='))?.split('=')[1]
        },
        body: JSON.stringify({
          reservation_id: reservationId,
          segment_id: segmentId
        })
      });

      const data = await response.json();

      if (data.success) {
        setSnackbar({
          open: true,
          message: 'Successfully initialized upgrade offers. Please refresh the page to see the updated data.',
          severity: 'success'
        });
      } else {
        setSnackbar({
          open: true,
          message: data.message || 'Failed to initialize upgrade offers',
          severity: 'error'
        });
      }
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Error initializing upgrade offers. Please try again.',
        severity: 'error'
      });
    } finally {
      setIsInitializing(false);
    }
  };

  const handleRefreshOffers = async () => {
    setIsRefreshing(true);
    try {
      // Get the current segment from the selected tab
      const currentSegment = segments[selectedTab];
      
      if (!currentSegment || !reservationId) {
        console.error('Missing required data: segment or reservation ID');
        return;
      }

      // Get the segment_id from the correct property
      const segmentId = currentSegment.segment_id || currentSegment.id;

      if (!segmentId) {
        console.error('Missing segment ID in segment data');
        return;
      }

      const response = await fetch('https://dev-api.withautopilot.com/admin_portal/refresh_upgrade_offers', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'token': document.cookie.split('; ').find(row => row.startsWith('token='))?.split('=')[1]
        },
        body: JSON.stringify({
          reservation_id: reservationId,
          segment_id: segmentId
        })
      });

      const data = await response.json();

      if (data.success) {
        setSnackbar({
          open: true,
          message: 'Successfully refreshed upgrade offers. Please refresh the page to see the updated data.',
          severity: 'success'
        });
      } else {
        setSnackbar({
          open: true,
          message: data.message || 'Failed to refresh upgrade offers',
          severity: 'error'
        });
      }
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Error refreshing upgrade offers. Please try again.',
        severity: 'error'
      });
    } finally {
      setIsRefreshing(false);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar(prev => ({ ...prev, open: false }));
  };

  if (!segments.length) {
    return (
      <Typography variant="body1" align="center">
        No upgrade offers available
      </Typography>
    );
  }

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6">Upgrade Offers</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ pb: 5 }}>
        <Box sx={{ width: '100%' }}>
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="flight segments tabs"
          >
            {segments.map((segment, index) => (
              <Tab 
                key={index}
                label={formatRoute(segment)}
                id={`segment-tab-${index}`}
                aria-controls={`segment-panel-${index}`}
                sx={{
                  minHeight: '48px',
                  '& .MuiTab-wrapper': {
                    display: 'flex',
                    alignItems: 'center',
                  }
                }}
              />
            ))}
          </Tabs>

          {segments.map((segment, index) => (
            <Box
              key={index}
              role="tabpanel"
              hidden={selectedTab !== index}
              id={`segment-panel-${index}`}
              aria-labelledby={`segment-tab-${index}`}
              sx={{ mt: 2 }}
            >
              {selectedTab === index && (
                <Stack spacing={3}>
                  {/* Upgrade Offers Graph */}
                  <Card>
                    <CardContent sx={{ p: 5 }}>
                      <Typography variant="h6" gutterBottom>
                        Upgrade Offer History
                      </Typography>
                      {renderUpgradeChart(segment)}
                      {renderUpgradeTable(segment)}
                    </CardContent>
                  </Card>

                  {/* Active Alerts */}
                  <Card>
                    <CardContent>
                      <Typography variant="h6" gutterBottom>
                        Active Upgrade Alerts ({segment.upgrade_alert_info.length})
                      </Typography>
                      <TableContainer>
                        <Table size="small">
                          <TableHead>
                            <TableRow>
                              <TableCell>Cabin</TableCell>
                              <TableCell>Target Price</TableCell>
                              <TableCell>Type</TableCell>
                              <TableCell>Status</TableCell>
                              <TableCell>Created</TableCell>
                              <TableCell>Last Updated</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {segment.upgrade_alert_info.map((alert, alertIndex) => (
                              <TableRow key={alertIndex}>
                                <TableCell>{alert.cabin_offered}</TableCell>
                                <TableCell>
                                  {alert.currency === 'cash' 
                                    ? `$${alert.price.toFixed(2)}` 
                                    : `${alert.price.toLocaleString()} miles`}
                                </TableCell>
                                <TableCell>{alert.currency === 'cash' ? 'Cash' : 'Miles'}</TableCell>
                                <TableCell>
                                  <Chip
                                    label={alert.is_active ? "Active" : "Inactive"}
                                    color={alert.is_active ? "success" : "default"}
                                    size="small"
                                  />
                                </TableCell>
                                <TableCell>{formatDateTime(alert.created_at)}</TableCell>
                                <TableCell>{formatDateTime(alert.updated_at)}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </CardContent>
                  </Card>
                </Stack>
              )}
            </Box>
          ))}
        </Box>
      </AccordionDetails>
      
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert 
          onClose={handleCloseSnackbar} 
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Accordion>
  );
}

export default UpgradeOffersSection; 