import React from 'react';
import { 
  Card, CardContent, Typography, Box, Divider, 
  Accordion, AccordionSummary, AccordionDetails,
  Stepper, Step, StepLabel, IconButton, Tooltip,
  Grid
} from '@mui/material';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import FlightLandIcon from '@mui/icons-material/FlightLand';
import AirplaneTicketIcon from '@mui/icons-material/AirplaneTicket';
import EventIcon from '@mui/icons-material/Event';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { formatDateTime } from '../utils/formatters';

function FlightInfoSection({ reservationData }) {
  const [copiedField, setCopiedField] = React.useState(null);

  const handleCopy = (text, field) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopiedField(field);
      setTimeout(() => setCopiedField(null), 2000);
    });
  };

  const renderFlightCard = (trip, index) => {
    if (!trip) return null;
    return (
      <Card key={index} sx={{ mb: 2, boxShadow: 3 }}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Trip {index + 1}: {trip.departure_airport} to {trip.arrival_airport}
            <Typography variant="caption" sx={{ ml: 1, color: 'text.secondary' }}>
              (Trip ID: {trip.flight_id})
            </Typography>
          </Typography>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={5}>
              <Box display="flex" alignItems="center">
                <FlightTakeoffIcon color="primary" sx={{ mr: 1 }} />
                <Typography variant="subtitle1">
                  {trip.departure_airport}
                </Typography>
              </Box>
              <Typography variant="body2" color="textSecondary">
                <EventIcon fontSize="small" sx={{ mr: 0.5, verticalAlign: 'middle' }} />
                {formatDateTime(trip.departure_datetime)}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Divider>
                <AirplaneTicketIcon color="action" />
              </Divider>
            </Grid>
            <Grid item xs={5}>
              <Box display="flex" alignItems="center" justifyContent="flex-end">
                <Typography variant="subtitle1">
                  {trip.arrival_airport}
                </Typography>
                <FlightLandIcon color="primary" sx={{ ml: 1 }} />
              </Box>
              <Typography variant="body2" color="textSecondary" align="right">
                <EventIcon fontSize="small" sx={{ mr: 0.5, verticalAlign: 'middle' }} />
                {formatDateTime(trip.arrival_datetime)}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Flight Details</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Stepper orientation="vertical">
              {trip.segments?.map((segment, segIndex) => (
                <Step key={segIndex} active={true}>
                  <StepLabel>
                    <Typography variant="subtitle1" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      Flight {segment.flight_number}: {segment.departure_airport} to {segment.arrival_airport}
                      <Tooltip title={copiedField === `flight_${index}_${segIndex}` ? "Copied!" : "Copy flight number"}>
                        <IconButton 
                          size="small" 
                          onClick={() => handleCopy(segment.flight_number.replace(/\s+/g, ''), `flight_${index}_${segIndex}`)}
                        >
                          <ContentCopyIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                      <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                        (Segment ID: {segment.segment_id})
                      </Typography>
                    </Typography>
                  </StepLabel>
                  <Box sx={{ ml: 3, mt: 1, mb: 2 }}>
                    <Typography variant="body2">
                      <strong>Departure:</strong> {formatDateTime(segment.departure_datetime)}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Arrival:</strong> {formatDateTime(segment.arrival_datetime)}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Aircraft:</strong> {segment.aircraft}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Cabin:</strong> {segment.cabin_name}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Seat:</strong> {segment.seat && segment.seat !== "{NULL}" ? segment.seat : 'Not assigned'}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Fare Class:</strong> {segment.fare_class?.trim()}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Fare Code:</strong> {segment.fare_code}
                    </Typography>
                    {segment.flight_status && (
                      <Typography variant="body2">
                        <strong>Flight Status:</strong> {segment.flight_status}
                      </Typography>
                    )}
                    {segment.remarks && (
                      <Typography variant="body2">
                        <strong>Remarks:</strong> {segment.remarks}
                      </Typography>
                    )}
                  </Box>
                </Step>
              ))}
            </Stepper>
          </AccordionDetails>
        </Accordion>
      </Card>
    );
  };

  return (
    <Accordion defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6">Flight Information</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {reservationData.trips?.map((trip, index) => renderFlightCard(trip, index))}
      </AccordionDetails>
    </Accordion>
  );
}

export default FlightInfoSection; 