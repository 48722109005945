import React from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
  Card,
  CardContent,
  IconButton,
  Tooltip
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

function PassengerInfoSection({ reservationData }) {
  const [copiedField, setCopiedField] = React.useState(null);

  const handleCopy = (text, field) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopiedField(field);
      setTimeout(() => setCopiedField(null), 2000);
    });
  };

  const renderCopyableField = (label, value, field) => (
    <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <strong>{label}:</strong> {value}
      <Tooltip title={copiedField === field ? "Copied!" : "Copy to clipboard"}>
        <IconButton size="small" onClick={() => handleCopy(value, field)}>
          <ContentCopyIcon fontSize="small" />
        </IconButton>
      </Tooltip>
    </Typography>
  );

  const passengers = reservationData.passengers || reservationData.trips?.[0]?.passengers || [];

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6">
          Passenger Information ({passengers.length} {
            passengers.length === 1 ? 'Passenger' : 'Passengers'
          })
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          {passengers.map((passenger, index) => (
            <Grid item xs={12} md={6} key={index}>
              <Card>
                <CardContent>
                  <Typography variant="h6">{passenger.first_name} {passenger.last_name}</Typography>
                  {renderCopyableField("Passenger ID", passenger.passenger_id, `passenger_id_${index}`)}
                  {renderCopyableField("Email", passenger.email, `email_${index}`)}
                  {renderCopyableField("Phone", passenger.phone, `phone_${index}`)}
                  {passenger.loyalty_info && (
                    <>
                      {renderCopyableField("Loyalty Number", passenger.loyalty_info.loyalty_number, `loyalty_number_${index}`)}
                      {renderCopyableField("Loyalty Status", passenger.loyalty_info.status, `loyalty_status_${index}`)}
                    </>
                  )}
                  {renderCopyableField("Status", passenger.status, `status_${index}`)}
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

export default PassengerInfoSection; 