import React, { useState } from 'react';
import {
  Accordion, AccordionSummary, AccordionDetails, Typography,
  Grid, Box, Button, TableContainer, Table,
  TableHead, TableRow, TableCell, TableBody, Paper,
  IconButton, CircularProgress
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RefreshIcon from '@mui/icons-material/Refresh';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { LineChart } from '@mui/x-charts/LineChart';
import { useTheme } from '@mui/material/styles';
import { formatCurrency, formatDateTime, formatToEST } from '../utils/formatters';
import Cookies from 'js-cookie';
import dayjs from 'dayjs';

function PricingInfoSection({ reservationData, onRefresh }) {
  const theme = useTheme();
  const [hoveredRow, setHoveredRow] = useState(null);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [lastRefreshedPrice, setLastRefreshedPrice] = useState(reservationData?.last_refreshed_price);
  const [lastRefreshedDateTime, setLastRefreshedDateTime] = useState(reservationData?.last_refreshed_datetime);
  const [justRefreshed, setJustRefreshed] = useState(false);

  const refreshPrice = async () => {
    setIsRefreshing(true);
    try {
      const token = Cookies.get('token');
      const response = await fetch('https://dev-api.withautopilot.com/admin_portal/refresh_reservation_price', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'token': token,
        },
        body: JSON.stringify({ reservation_id: reservationData.reservation_id }),
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setLastRefreshedPrice(data.price.toString());
      setLastRefreshedDateTime(new Date().toISOString());
      setJustRefreshed(true);
    } catch (error) {
      console.error('Error refreshing price:', error);
    } finally {
      setIsRefreshing(false);
    }
  };

  const getPriceDifference = (current, last) => {
    const currentPrice = parseFloat(current);
    const lastPrice = parseFloat(last);
    if (isNaN(currentPrice) || isNaN(lastPrice)) return null;
    return currentPrice - lastPrice;
  };

  const priceDifference = getPriceDifference(reservationData.current_effective_price, lastRefreshedPrice);
  const isPriceLower = priceDifference > 0;

  const renderHistoricalPriceChart = () => {
    if (!reservationData.price_history?.length) {
      return (
        <Typography variant="body1" align="center">
          No Historical Pricing Data to Display
        </Typography>
      );
    }

    const data = reservationData.price_history.map(entry => ({
      date: new Date(entry.timestamp),
      price: parseFloat(entry.price)
    })).filter(point => !isNaN(point.date.getTime()) && !isNaN(point.price));

    if (!data.length) {
      return (
        <Typography variant="body1" align="center">
          Can't display historical data
        </Typography>
      );
    }

    const minPrice = Math.min(...data.map(d => d.price));
    const maxPrice = Math.max(...data.map(d => d.price));

    return (
      <Box sx={{ width: '100%', height: 300 }}>
        <LineChart
          dataset={data}
          xAxis={[{ 
            dataKey: 'date',
            scaleType: 'time',
            valueFormatter: (date) => {
              if (!(date instanceof Date) || isNaN(date.getTime())) {
                return 'Invalid Date';
              }
              return dayjs(date).format('MMM D, YYYY h:mm A');
            },
          }]}
          yAxis={[{ 
            dataKey: 'price',
            scaleType: 'linear',
            valueFormatter: (value) => formatCurrency(value),
            min: Math.floor(minPrice * 0.9),
            max: Math.ceil(maxPrice * 1.1),
          }]}
          series={[{
            dataKey: 'price',
            area: true,
            color: theme.palette.primary.main,
            areaOpacity: 0.5,
            valueFormatter: (value) => formatCurrency(value),
          }]}
          height={300}
          margin={{ top: 10, bottom: 30, left: 60, right: 10 }}
          slotProps={{ legend: { hidden: true }}}
        />
      </Box>
    );
  };

  const renderHistoricalPriceTable = () => {
    if (!reservationData.price_history?.length) {
      return (
        <Typography variant="body1" align="center">
          No Historical Pricing Data to Display
        </Typography>
      );
    }

    return (
      <TableContainer component={Paper} sx={{ maxHeight: 250 }}>
        <Table stickyHeader aria-label="historical price table">
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell align="right">Price</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {reservationData.price_history.map((row, index) => (
              <TableRow 
                key={index}
                sx={{ 
                  '&:nth-of-type(odd)': { backgroundColor: 'action.hover' },
                  '&:hover': { backgroundColor: 'action.selected' },
                  ...(hoveredRow === index && { backgroundColor: 'primary.light' }),
                }}
                onMouseEnter={() => setHoveredRow(index)}
                onMouseLeave={() => setHoveredRow(null)}
              >
                <TableCell>{formatDateTime(row.timestamp)}</TableCell>
                <TableCell align="right">{formatCurrency(row.price)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
          <Typography variant="h6">Pricing Information</Typography>
          <a 
            href={reservationData?.search_link} 
            target="_blank" 
            rel="noopener noreferrer"
            style={{ textDecoration: 'none' }}
          >
            <Button
              variant="outlined"
              size="small"
              endIcon={<Box component="span" sx={{ transform: 'rotate(-45deg)' }}>→</Box>}
              sx={{ 
                mr: 4,
                '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' }
              }}
            >
              Price Directly with {reservationData?.airline?.charAt(0).toUpperCase() + reservationData?.airline?.slice(1).toLowerCase()}
            </Button>
          </a>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h4" fontWeight="bold">
              {reservationData.is_award ? formatCurrency(reservationData.award_price, true) : formatCurrency(reservationData.payment_amount)}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Total Original {reservationData.is_award ? 'Miles' : 'Payment Amount'}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h4" fontWeight="bold">
              {formatCurrency(reservationData.total_saved_amount, reservationData.is_award)}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Total Saved on this Reservation
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h4" fontWeight="bold">
              {formatCurrency(reservationData.current_effective_price, reservationData.is_award)}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Current Effective {reservationData.is_award ? 'Miles' : 'Price'}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {reservationData.is_award ? 'Miles' : 'Fare'} Paid, Adjusted for Lowest {reservationData.is_award ? 'Miles' : 'Fare'} Guarantee
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box sx={{ flexGrow: 1, opacity: isRefreshing ? 0.5 : 1 }}>
                <Typography variant="h4" fontWeight="bold" sx={isPriceLower ? { color: theme.palette.success.main } : undefined}>
                  {formatCurrency(lastRefreshedPrice, reservationData.is_award)}
                </Typography>
              </Box>
              <IconButton 
                onClick={refreshPrice} 
                disabled={isRefreshing}
                sx={{ ml: 1 }}
              >
                {isRefreshing ? <CircularProgress size={24} /> : <RefreshIcon />}
              </IconButton>
            </Box>
            <Typography variant="body2" color="textSecondary">
              Last {reservationData.is_award ? 'Miles' : 'Price'} Seen
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {justRefreshed 
                ? `${reservationData.is_award ? 'Miles' : 'Price'} Found Just Now (${formatToEST(lastRefreshedDateTime)})`
                : formatToEST(lastRefreshedDateTime)}
            </Typography>
            {isPriceLower && (
              <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                <ArrowDownwardIcon sx={{ color: theme.palette.success.main, mr: 0.5 }} />
                <Typography variant="body2" sx={{ color: theme.palette.success.main, fontWeight: 'bold' }}>
                  {formatCurrency(Math.abs(priceDifference), reservationData.is_award)} lower
                </Typography>
              </Box>
            )}
          </Grid>
        </Grid>

        <Box mt={4}>
          <Typography variant="h6" gutterBottom>Historical Prices</Typography>
          {renderHistoricalPriceChart()}
          <Box mt={2}>
            <Typography variant="h6" gutterBottom>
              Price History Table (Price Refreshed {reservationData.price_history?.length || 0} Times)
            </Typography>
            {renderHistoricalPriceTable()}
          </Box>

          {reservationData?.savings_info && (
            <Box mt={4}>
              <Typography variant="h6" gutterBottom>
                Credit History ({reservationData.savings_info.price_change_events?.length || 0} Events)
              </Typography>
              {reservationData.savings_info.price_change_events?.length > 0 ? (
                <TableContainer component={Paper} sx={{ maxHeight: 400 }}>
                  <Table stickyHeader aria-label="credit history table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Date</TableCell>
                        <TableCell align="right">Original Price</TableCell>
                        <TableCell align="right">New Price</TableCell>
                        <TableCell align="right">Savings</TableCell>
                        <TableCell>Type</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {reservationData.savings_info.price_change_events.map((event, index) => (
                        <TableRow 
                          key={event.event_id}
                          sx={{ 
                            '&:nth-of-type(odd)': { backgroundColor: 'action.hover' },
                            '&:hover': { backgroundColor: 'action.selected' },
                            ...(hoveredRow === `credit-${index}` && { backgroundColor: 'primary.light' }),
                            cursor: 'pointer',
                          }}
                          onMouseEnter={() => setHoveredRow(`credit-${index}`)}
                          onMouseLeave={() => setHoveredRow(null)}
                        >
                          <TableCell>{formatDateTime(event.event_date)}</TableCell>
                          <TableCell align="right">{formatCurrency(event.original_price)}</TableCell>
                          <TableCell align="right">{formatCurrency(event.new_price)}</TableCell>
                          <TableCell 
                            align="right"
                            sx={{ 
                              color: theme.palette.success.main,
                              fontWeight: 'bold'
                            }}
                          >
                            {formatCurrency(event.price_difference)}
                          </TableCell>
                          <TableCell sx={{ textTransform: 'capitalize' }}>{event.change_type}</TableCell>
                          {hoveredRow === `credit-${index}` && event.voucher_credits?.length > 0 && (
                            <Box
                              sx={{
                                position: 'absolute',
                                right: '100%',
                                top: '50%',
                                transform: 'translateY(-50%)',
                                backgroundColor: theme.palette.background.paper,
                                boxShadow: theme.shadows[4],
                                borderRadius: 1,
                                p: 2,
                                zIndex: 1,
                                minWidth: 300,
                                maxWidth: 400,
                              }}
                            >
                              <Typography variant="subtitle2" gutterBottom>
                                Voucher Details
                              </Typography>
                              {event.voucher_credits.map((credit, creditIndex) => (
                                <Box key={creditIndex} sx={{ mb: creditIndex !== event.voucher_credits.length - 1 ? 2 : 0 }}>
                                  <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.5 }}>
                                    <Typography variant="body2" color="textSecondary">Amount:</Typography>
                                    <Typography variant="body2">{formatCurrency(credit.amount)}</Typography>
                                  </Box>
                                  <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.5 }}>
                                    <Typography variant="body2" color="textSecondary">Reference:</Typography>
                                    <Typography variant="body2">{credit.reference_number}</Typography>
                                  </Box>
                                  <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.5 }}>
                                    <Typography variant="body2" color="textSecondary">Issue Date:</Typography>
                                    <Typography variant="body2">{formatDateTime(credit.issue_date)}</Typography>
                                  </Box>
                                  <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.5 }}>
                                    <Typography variant="body2" color="textSecondary">Expires:</Typography>
                                    <Typography variant="body2">{formatDateTime(credit.expiration_date)}</Typography>
                                  </Box>
                                  {credit.notes && (
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                      <Typography variant="body2" color="textSecondary">Notes:</Typography>
                                      <Typography variant="body2">{credit.notes}</Typography>
                                    </Box>
                                  )}
                                </Box>
                              ))}
                            </Box>
                          )}
                        </TableRow>
                      ))}
                      <TableRow sx={{ backgroundColor: theme.palette.action.hover }}>
                        <TableCell colSpan={3} align="right">
                          <Typography variant="subtitle2">Total Savings:</Typography>
                        </TableCell>
                        <TableCell align="right" sx={{ color: theme.palette.success.main, fontWeight: 'bold' }}>
                          {formatCurrency(reservationData.savings_info.total_savings)}
                        </TableCell>
                        <TableCell />
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <Paper elevation={1} sx={{ p: 2, textAlign: 'center' }}>
                  <Typography variant="body1" color="textSecondary">
                    No credit history available
                  </Typography>
                </Paper>
              )}
            </Box>
          )}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}

export default PricingInfoSection; 