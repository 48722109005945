import React, { useState, useEffect, useCallback } from 'react';
import { TextField, Button, Box, Typography, CircularProgress, Alert, FormControl, InputLabel, Select, OutlinedInput, MenuItem, Checkbox, ListItemText } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import ReservationDisplayComponent from './ReservationDisplayComponent';
import { getToken } from '../utils/auth';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const filterOptions = [
  'is_active',
  'is_auto_rebook_enabled',
  'is_upcoming',
  'is_tracked',
  'is_archived',
  'is_visible'
];

function ReservationLookup() {
  const [searchCriteria, setSearchCriteria] = useState({
    reservation_id: '',
    pnr: '',
    user_id: '',
  });
  const [filters, setFilters] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [results, setResults] = useState(null);
  const [filteredResults, setFilteredResults] = useState(null);
  const [noReservationsFound, setNoReservationsFound] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const handleSubmit = useCallback(async (e, userIdOverride = null) => {
    if (e) e.preventDefault();
    setIsLoading(true);
    setError(null);
    setResults(null);
    setFilteredResults(null);
    setNoReservationsFound(false);

    const payload = Object.entries(searchCriteria).reduce((acc, [key, value]) => {
      if (value) acc[key] = value;
      return acc;
    }, {});

    if (userIdOverride) {
      payload.user_id = userIdOverride;
    }

    if (Object.values(payload).every(x => x === '') && filters.length === 0) {
      setError("Please enter a Reservation ID, PNR, User ID, or select filters");
      setIsLoading(false);
      return;
    }

    // Add filters to payload
    filters.forEach(filter => {
      payload[filter] = true;
    });

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("token", getToken());

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify(payload),
      redirect: 'follow'
    };

    try {
      const response = await fetch("https://dev-api.withautopilot.com/admin_portal/find_reservations", requestOptions);
      //const response = await fetch("http://127.0.0.1:8080/admin_portal/find_reservations", requestOptions);
      if (response.status === 404) {
        setNoReservationsFound(true);
        setResults([]);
        setFilteredResults([]);
      } else if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      } else {
        const result = await response.json();
        console.log("Reservation data:", result);
        
        const reservationsArray = Array.isArray(result) ? result : [result];
        setResults(reservationsArray);
        setFilteredResults(reservationsArray);
      }
    } catch (e) {
      console.error("There was a problem with the fetch operation:", e);
      setError("An error occurred while fetching the data. Please try again.");
    } finally {
      setIsLoading(false);
    }
  }, [searchCriteria, filters]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const userIdFromQuery = queryParams.get('user_id');
    if (userIdFromQuery && userIdFromQuery !== searchCriteria.user_id) {
      setSearchCriteria(prev => ({ ...prev, user_id: userIdFromQuery }));
      handleSubmit(null, userIdFromQuery);
      navigate('/reservation', { replace: true });
    }
  }, [location.search, searchCriteria.user_id, handleSubmit, navigate]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSearchCriteria(prev => ({ ...prev, [name]: value }));
  };

  const handleFilterChange = (event) => {
    const {
      target: { value },
    } = event;
    setFilters(
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  useEffect(() => {
    if (results) {
      const filtered = results.filter(reservation => 
        filters.every(filter => reservation[filter] === true)
      );
      setFilteredResults(filtered);
    }
  }, [results, filters]);

  return (
    <Box>
      <Typography variant="h4" component="h1" gutterBottom>
        Reservation Lookup
      </Typography>
      <form onSubmit={handleSubmit}>
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, marginBottom: 2, alignItems: 'center' }}>
          <TextField
            label="Reservation ID"
            name="reservation_id"
            value={searchCriteria.reservation_id}
            onChange={handleInputChange}
            variant="outlined"
            size="small"
          />
          <TextField
            label="PNR"
            name="pnr"
            value={searchCriteria.pnr}
            onChange={handleInputChange}
            variant="outlined"
            size="small"
          />
          <TextField
            label="User ID"
            name="user_id"
            value={searchCriteria.user_id}
            onChange={handleInputChange}
            variant="outlined"
            size="small"
          />
          <FormControl sx={{ m: 1, width: 300 }} size="small">
            <InputLabel id="multiple-checkbox-label">Filters</InputLabel>
            <Select
              labelId="multiple-checkbox-label"
              id="multiple-checkbox"
              multiple
              value={filters}
              onChange={handleFilterChange}
              input={<OutlinedInput label="Filters" />}
              renderValue={(selected) => selected.join(', ')}
              MenuProps={MenuProps}
            >
              {filterOptions.map((name) => (
                <MenuItem key={name} value={name}>
                  <Checkbox checked={filters.indexOf(name) > -1} />
                  <ListItemText primary={name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button type="submit" variant="contained" disabled={isLoading}>
            {isLoading ? <CircularProgress size={24} /> : "Search"}
          </Button>
        </Box>
      </form>

      {error && <Alert severity="error" sx={{ mt: 2 }}>{error}</Alert>}
      {noReservationsFound && <Alert severity="info" sx={{ mt: 2 }}>No reservations found.</Alert>}
      {filteredResults && filteredResults.length > 0 && filteredResults.map((reservation, index) => (
        <ReservationDisplayComponent key={index} reservationData={reservation} />
      ))}
    </Box>
  );
}

export default ReservationLookup;