// /Users/sam/Desktop/autopilot-admin-app/src/components/HomePage.js

import React, { useState, useEffect, useCallback } from 'react';
import { Box, Typography, Grid, Paper, CircularProgress, List, ListItem, ListItemIcon, ListItemText, FormGroup, FormControlLabel, Switch, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { styled } from '@mui/material/styles';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CountUp from 'react-countup';
import { getToken } from '../utils/auth';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import Chart from 'react-apexcharts';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import '../styles/fonts.css';

const theme = createTheme({
  palette: {
    primary: {
      main: '#535aff',
    },
  },
  typography: {
    fontFamily: '"Haffer Trial", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
    h1: {
      fontFamily: '"Haffer Trial", sans-serif',
      fontWeight: 700,
    },
    h2: {
      fontFamily: '"Haffer Trial", sans-serif',
      fontWeight: 700,
    },
    h3: {
      fontFamily: '"Haffer Trial", sans-serif',
      fontWeight: 600,
    },
    h4: {
      fontFamily: '"Haffer Trial", sans-serif',
      fontWeight: 600,
    },
    h5: {
      fontFamily: '"Haffer Trial", sans-serif',
      fontWeight: 500,
    },
    h6: {
      fontFamily: '"Haffer Trial", sans-serif',
      fontWeight: 500,
    },
    subtitle1: {
      fontFamily: '"Haffer Trial", sans-serif',
      fontWeight: 500,
    },
    subtitle2: {
      fontFamily: '"Haffer Trial", sans-serif',
      fontWeight: 500,
    },
    body1: {
      fontFamily: '"Haffer Trial", sans-serif',
      fontWeight: 400,
    },
    body2: {
      fontFamily: '"Haffer Trial", sans-serif',
      fontWeight: 400,
    },
    button: {
      fontFamily: '"Haffer Trial", sans-serif',
      fontWeight: 500,
    },
    caption: {
      fontFamily: '"Haffer Trial", sans-serif',
      fontWeight: 400,
    },
    overline: {
      fontFamily: '"Haffer Trial", sans-serif',
      fontWeight: 400,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Haffer Trial';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: url('https://haffer-trial-font.s3.us-east-2.amazonaws.com/Haffer-TRIAL-Regular.woff') format('woff');
        }
      `,
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: '#535aff',
            color: '#fff',
            '&:hover': {
              backgroundColor: '#4248cc',
            },
          },
        },
      },
    },
  },
});

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: '0 8px 16px rgba(83, 90, 255, 0.1)',
  },
}));

const StatTitle = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  color: '#535aff',
  fontFamily: '"Haffer Trial", sans-serif',
  fontWeight: 600,
}));

const StatValue = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  color: theme.palette.text.primary,
  fontFamily: '"Haffer Trial", sans-serif',
}));

const easeInOutCubic = (t) => t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;

const GreenText = styled(Typography)(({ theme }) => ({
  color: theme.palette.success.main,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '0.875rem',
  marginTop: theme.spacing(1),
}));

const StatusPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
}));

const ChartControls = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
}));

const StatusList = styled(List)({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
  gap: '16px',
});

const StatusItem = styled(ListItem)({
  padding: '8px 0',
});

const StatusText = styled(ListItemText)({
  margin: 0,
  marginLeft: '8px',
});

const LogoImage = styled('img')({
  height: '32px',
  marginRight: '12px',
  verticalAlign: 'middle',
});

export default function HomePage() {
  const [statistics, setStatistics] = useState(null);
  const [loading, setLoading] = useState(true);
  const [growthData, setGrowthData] = useState({
    users: [],
    reservations: []
  });

  const [showUsers, setShowUsers] = useState(true);
  const [showReservations, setShowReservations] = useState(true);
  const [dataType, setDataType] = useState('total');

  const fetchDashboardData = useCallback(async () => {
    try {
      const token = getToken();
      const response = await fetch('https://dev-api.withautopilot.com/admin_portal/get_statistics', {
      //const response = await fetch('http://127.0.0.1:8080/admin_portal/get_statistics', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'token': token
        },
      });
      if (response.ok) {
        const data = await response.json();
        setStatistics(data);
        processGrowthData(data.growth_data);
        setLoading(false);
      } else {
        console.error('Failed to fetch dashboard data');
        setLoading(false);
      }
    } catch (error) {
      console.error('Error fetching dashboard data:', error);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchDashboardData();
  }, [fetchDashboardData]);

  const processGrowthData = (growthData) => {
    const users = { new: [], total: [] };
    const reservations = { new: [], total: [] };
    Object.entries(growthData).forEach(([date, data]) => {
      const timestamp = new Date(date).getTime();
      users.new.push([timestamp, data.users.new]);
      users.total.push([timestamp, data.users.total]);
      reservations.new.push([timestamp, data.reservations.new]);
      reservations.total.push([timestamp, data.reservations.total]);
    });
    setGrowthData({ users, reservations });
  };

  const chartOptions = {
    chart: {
      type: 'line',
      height: 350
    },
    title: {
      text: `${dataType === 'total' ? 'Total' : 'New'} User and Reservation Growth over Time`,
      align: 'left'
    },
    xaxis: {
      type: 'datetime'
    },
    yaxis: showUsers && showReservations
      ? [
          {
            title: { text: 'Users' },
          },
          {
            opposite: true,
            title: { text: 'Reservations' },
          }
        ]
      : [{ title: { text: showUsers ? 'Users' : 'Reservations' } }],
    noData: {
      text: 'No data to display',
      align: 'center',
      verticalAlign: 'middle',
      style: {
        fontSize: '20px',
      }
    }
  };

  const chartSeries = [
    ...(showUsers ? [{
      name: 'Users',
      data: growthData.users[dataType] || []
    }] : []),
    ...(showReservations ? [{
      name: 'Reservations',
      data: growthData.reservations[dataType] || []
    }] : [])
  ];

  const handleDataTypeChange = (event, newDataType) => {
    if (newDataType !== null) {
      setDataType(newDataType);
    }
  };

  const allSystemsOperational = statistics?.system_status && 
    Object.values(statistics.system_status).every(status => status === true);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ flexGrow: 1, p: 3 }}>
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center',
          mb: 4,
        }}>
          <LogoImage 
            src="https://cdn.prod.website-files.com/660c98c8b15b9e84551e4553/6710229ee2ff495668a4beb5_Frame.svg"
            alt="Beluga Logo"
          />
          <Typography 
            variant="h4" 
            component="div" 
            sx={{ 
              fontWeight: 700,
              color: '#535aff',
              fontFamily: '"Haffer Trial", sans-serif',
            }}
          >
            Beluga Dashboard
          </Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <Item elevation={3}>
              <StatTitle variant="h6">Total Users</StatTitle>
              <StatValue variant="h4">
                <CountUp 
                  end={statistics.number_of_users} 
                  duration={3} 
                  separator="," 
                  easingFn={easeInOutCubic}
                />
              </StatValue>
              {statistics.users_added_last_24_hours > 0 && (
                <GreenText>
                  +{statistics.users_added_last_24_hours} Last 24h
                  <ArrowUpwardIcon fontSize="small" sx={{ ml: 0.5 }} />
                </GreenText>
              )}
            </Item>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Item elevation={3}>
              <StatTitle variant="h6">Total Amount Saved</StatTitle>
              <StatValue variant="h4">
                $<CountUp 
                  end={statistics.total_amount_saved} 
                  duration={3} 
                  separator="," 
                  decimals={2} 
                  easingFn={easeInOutCubic}
                />
              </StatValue>
            </Item>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Item elevation={3}>
              <StatTitle variant="h6">Total Saved (Vouchers)</StatTitle>
              <StatValue variant="h4">
                $<CountUp 
                  end={statistics.total_saved_credits} 
                  duration={3} 
                  separator="," 
                  decimals={2} 
                  easingFn={easeInOutCubic}
                />
              </StatValue>
            </Item>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Item elevation={3}>
              <StatTitle variant="h6">Total Reservations</StatTitle>
              <StatValue variant="h4">
                <CountUp 
                  end={statistics.total_number_of_reservations} 
                  duration={3} 
                  separator="," 
                  easingFn={easeInOutCubic}
                />
              </StatValue>
              {statistics.reservations_added_last_24_hours > 0 && (
                <GreenText>
                  +{statistics.reservations_added_last_24_hours} Last 24h
                  <ArrowUpwardIcon fontSize="small" sx={{ ml: 0.5 }} />
                </GreenText>
              )}
            </Item>
          </Grid>
          <Grid item xs={12} md={6}>
            <StatusPaper elevation={3}>
              <Typography variant="h6" gutterBottom>System Status</Typography>
              <Typography 
                variant="subtitle1" 
                sx={{ 
                  mb: 2, 
                  color: allSystemsOperational ? 'success.main' : 'error.main',
                  fontWeight: 'bold'
                }}
              >
                {allSystemsOperational ? 'All Systems Operational' : 'Problem with a System'}
              </Typography>
              <StatusList>
                {statistics?.system_status && Object.entries(statistics.system_status).map(([key, value]) => (
                  <StatusItem key={key}>
                    <ListItemIcon>
                      {value ? 
                        <CheckCircleIcon sx={{ color: '#00C853' }} /> : 
                        <CancelIcon sx={{ color: '#D50000' }} />
                      }
                    </ListItemIcon>
                    <StatusText primary={key.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())} />
                  </StatusItem>
                ))}
              </StatusList>
            </StatusPaper>
          </Grid>
          <Grid item xs={12}>
            <Item elevation={3}>
              <ChartControls>
                <FormGroup row>
                  <FormControlLabel
                    control={<Switch checked={showUsers} onChange={() => setShowUsers(!showUsers)} />}
                    label="Users"
                  />
                  <FormControlLabel
                    control={<Switch checked={showReservations} onChange={() => setShowReservations(!showReservations)} />}
                    label="Reservations"
                  />
                </FormGroup>
                <ToggleButtonGroup
                  value={dataType}
                  exclusive
                  onChange={handleDataTypeChange}
                >
                  <ToggleButton value="total">Total</ToggleButton>
                  <ToggleButton value="new">New</ToggleButton>
                </ToggleButtonGroup>
              </ChartControls>
              {(showUsers || showReservations) ? (
                <Chart
                  options={chartOptions}
                  series={chartSeries}
                  type="line"
                  height={350}
                />
              ) : (
                <Box sx={{ height: 350, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <Typography variant="h6">Please select at least one data series to display</Typography>
                </Box>
              )}
            </Item>
          </Grid>
        </Grid>
      </Box>
    </ThemeProvider>
  );
}
