import React from 'react';
import { Box, Paper, Typography, Button, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';

function StatusSection({ reservationData, onOpenTrackingDialog }) {
  const theme = useTheme();

  const groupChips = (data) => ({
    tracking: [
      { label: 'Active', value: data.is_active },
      { label: 'Tracked', value: data.is_tracked },
      { label: 'Visible', value: data.is_visible },
      { label: 'Archived', value: data.is_archived },
      { label: 'Upcoming', value: data.is_upcoming },
      { label: 'Auto Rebook', value: data.is_auto_rebook_enabled },
    ],
    info: [
      { label: 'Award', value: data.is_award },
      { label: 'Basic Economy', value: data.is_basic_economy },
      { label: 'Elite Upgrade', value: data.is_elite_upgrade },
      { label: 'Refundable', value: data.is_refundable },
      { label: 'Direct Booking', value: data.is_direct_booking },
    ]
  });

  const groups = groupChips(reservationData);

  return (
    <Box sx={{ mb: 2, display: 'flex', justifyContent: 'center', width: '100%' }}>
      <Paper 
        elevation={0}
        sx={{ 
          maxWidth: '900px',
          width: '100%',
          p: '20px 24px',
          borderRadius: '16px',
          bgcolor: 'background.paper',
          border: `1px solid ${theme.palette.divider}`,
          position: 'relative'
        }}
      >
        <Stack spacing={2.5} width="100%">
          {/* Tracking Status */}
          <Box>
            <Typography 
              variant="subtitle2" 
              sx={{ 
                fontWeight: 500,
                color: theme.palette.text.secondary,
                fontSize: '0.875rem',
                mb: 1.25,
                textAlign: 'center'
              }}
            >
              Tracking Status
            </Typography>
            <Box sx={{ 
              display: 'flex', 
              flexWrap: 'wrap', 
              gap: '6px',
              justifyContent: 'center'
            }}>
              {groups.tracking.map((item, index) => (
                <Button
                  key={index}
                  size="small"
                  disableElevation
                  sx={{ 
                    minWidth: 'auto',
                    padding: '4px 12px',
                    borderRadius: '20px',
                    backgroundColor: item.value ? theme.palette.primary.main : 'transparent',
                    color: item.value ? 'white' : theme.palette.text.secondary,
                    border: item.value ? 'none' : `1px solid ${theme.palette.divider}`,
                    '&:hover': {
                      backgroundColor: item.value ? theme.palette.primary.dark : 'rgba(0, 0, 0, 0.04)',
                    },
                    textTransform: 'none',
                    fontSize: '0.75rem',
                    fontWeight: 500,
                    height: '26px',
                    lineHeight: 1,
                    boxShadow: item.value ? '0 2px 4px rgba(25, 118, 210, 0.16)' : 'none',
                    transition: 'all 0.2s ease-in-out',
                    '&:active': {
                      transform: 'scale(0.98)'
                    }
                  }}
                  onClick={onOpenTrackingDialog}
                >
                  {item.label}
                </Button>
              ))}
            </Box>
          </Box>

          {/* Reservation Type */}
          <Box>
            <Typography 
              variant="subtitle2" 
              sx={{ 
                fontWeight: 500,
                color: theme.palette.text.secondary,
                fontSize: '0.875rem',
                mb: 1.25,
                textAlign: 'center'
              }}
            >
              Reservation Type
            </Typography>
            <Box sx={{ 
              display: 'flex', 
              flexWrap: 'wrap', 
              gap: '6px',
              justifyContent: 'center'
            }}>
              {groups.info.map((item, index) => (
                <Button
                  key={index}
                  size="small"
                  disableElevation
                  sx={{ 
                    minWidth: 'auto',
                    padding: '4px 12px',
                    borderRadius: '20px',
                    backgroundColor: item.value ? theme.palette.error.main : 'transparent',
                    color: item.value ? 'white' : theme.palette.text.secondary,
                    border: item.value ? 'none' : `1px solid ${theme.palette.divider}`,
                    '&:hover': {
                      backgroundColor: item.value ? theme.palette.error.dark : 'rgba(0, 0, 0, 0.04)',
                    },
                    textTransform: 'none',
                    fontSize: '0.75rem',
                    fontWeight: 500,
                    height: '26px',
                    lineHeight: 1,
                    boxShadow: item.value ? '0 2px 4px rgba(211, 47, 47, 0.16)' : 'none',
                    transition: 'all 0.2s ease-in-out',
                    '&:active': {
                      transform: 'scale(0.98)'
                    }
                  }}
                  onClick={onOpenTrackingDialog}
                >
                  {item.label}
                </Button>
              ))}
            </Box>
          </Box>
        </Stack>
      </Paper>
    </Box>
  );
}

export default StatusSection; 