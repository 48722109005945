import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  CircularProgress
} from '@mui/material';
import Cookies from 'js-cookie';

function RefreshDialog({ open, onClose, reservationData, onRefresh, setRefreshStatus }) {
  const [isRefreshing, setIsRefreshing] = useState(false);

  const handleRefreshReservation = async () => {
    setIsRefreshing(true);
    try {
      const token = Cookies.get('token');
      const response = await fetch('https://dev-api.withautopilot.com/admin_portal/refresh_pnr', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'token': token,
        },
        body: JSON.stringify({
          user_id: reservationData.user_id,
          reservation_id: reservationData.reservation_id
        }),
      });

      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.status}`);
      }

      const data = await response.json();

      if (data && (data.response === "Complete" || data.response === "complete")) {
        setRefreshStatus('success');
        if (typeof onRefresh === 'function') {
          onRefresh();
        }
      } else {
        throw new Error('Unexpected response from server');
      }
    } catch (error) {
      console.error('Error refreshing reservation:', error);
      setRefreshStatus('error');
    } finally {
      setIsRefreshing(false);
      onClose();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Confirm Reservation Refresh
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          This action cannot be undone. Are you sure you want to refresh reservation {reservationData.reservation_id}?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button 
          onClick={handleRefreshReservation} 
          color="primary" 
          autoFocus
          disabled={isRefreshing}
        >
          {isRefreshing ? <CircularProgress size={24} /> : 'Confirm'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default RefreshDialog; 