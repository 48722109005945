import React from 'react';
import { Box, Paper, Typography, Button, IconButton, Tooltip, useTheme, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import RefreshIcon from '@mui/icons-material/Refresh';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

function HeaderSection({ reservationData, onOpenRefreshDialog }) {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [copiedField, setCopiedField] = React.useState(null);

  const handleCopy = (text, field) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopiedField(field);
      setTimeout(() => setCopiedField(null), 2000);
    });
  };

  return (
    <Box sx={{ 
      width: '100%',
      display: 'flex',
      flexDirection: isMobile ? 'column' : 'row',
      justifyContent: 'space-between',
      alignItems: isMobile ? 'stretch' : 'flex-start',
      gap: 2,
      mb: 4
    }}>
      {/* Left side - Info Module */}
      <Paper
        elevation={0}
        sx={{ 
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          alignItems: isMobile ? 'stretch' : 'center',
          gap: isMobile ? 1.5 : 3,
          py: 2,
          px: 3,
          borderRadius: '8px',
          border: `1px solid ${theme.palette.divider}`,
          backgroundColor: theme.palette.background.paper,
          width: isMobile ? '100%' : 'auto'
        }}
      >
        {/* Reservation ID */}
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          gap: 1,
          justifyContent: isMobile ? 'space-between' : 'flex-start'
        }}>
          <Typography color="text.secondary">Reservation ID:</Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Typography 
              sx={{ 
                fontSize: '1.5rem',
                fontWeight: 700,
                color: theme.palette.primary.main,
                backgroundColor: `${theme.palette.primary.main}15`,
                padding: '2px 8px',
                borderRadius: '4px'
              }}
            >
              {reservationData.reservation_id}
            </Typography>
            <Tooltip title={copiedField === 'reservation_id' ? "Copied!" : "Copy ID"}>
              <IconButton 
                size="small" 
                onClick={() => handleCopy(reservationData.reservation_id.toString(), 'reservation_id')}
                sx={{ 
                  padding: '2px',
                  '&:hover': { 
                    backgroundColor: `${theme.palette.primary.main}15`
                  }
                }}
              >
                <ContentCopyIcon sx={{ fontSize: 16 }} />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>

        {/* PNR */}
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          gap: 1,
          justifyContent: isMobile ? 'space-between' : 'flex-start'
        }}>
          <Typography color="text.secondary">PNR:</Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Typography 
              sx={{ 
                fontSize: '1.5rem',
                fontWeight: 700,
                color: theme.palette.primary.main,
                backgroundColor: `${theme.palette.primary.main}15`,
                padding: '2px 8px',
                borderRadius: '4px'
              }}
            >
              {reservationData.pnr}
            </Typography>
            <Tooltip title={copiedField === 'pnr' ? "Copied!" : "Copy PNR"}>
              <IconButton 
                size="small" 
                onClick={() => handleCopy(reservationData.pnr, 'pnr')}
                sx={{ 
                  padding: '2px',
                  '&:hover': { 
                    backgroundColor: `${theme.palette.primary.main}15`
                  }
                }}
              >
                <ContentCopyIcon sx={{ fontSize: 16 }} />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>

        {/* User ID */}
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          gap: 1,
          justifyContent: isMobile ? 'space-between' : 'flex-start'
        }}>
          <Typography color="text.secondary">User ID:</Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Typography fontWeight="500">{reservationData.user_id}</Typography>
            <IconButton 
              size="small" 
              onClick={() => navigate(`/users?user_id=${reservationData.user_id}`)}
              sx={{ 
                color: theme.palette.primary.main,
                padding: '2px',
                '&:hover': { 
                  backgroundColor: `${theme.palette.primary.main}15`
                }
              }}
            >
              <ArrowForwardIcon sx={{ fontSize: 16 }} />
            </IconButton>
          </Box>
        </Box>
      </Paper>

      {/* Right side - Action Buttons */}
      <Box sx={{ 
        display: 'flex', 
        gap: 2,
        flexDirection: isMobile ? 'column' : 'row',
        width: isMobile ? '100%' : 'auto'
      }}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<EditIcon />}
          onClick={() => navigate(`/record-refaring?reservation_id=${reservationData.reservation_id}`)}
          sx={{ 
            borderRadius: '8px',
            textTransform: 'none',
            boxShadow: 'none',
            '&:hover': { 
              boxShadow: '0 2px 8px rgba(25, 118, 210, 0.25)'
            },
            width: isMobile ? '100%' : 'auto'
          }}
        >
          Record Refaring
        </Button>
        <Button
          variant="outlined"
          startIcon={<RefreshIcon />}
          onClick={onOpenRefreshDialog}
          sx={{ 
            borderRadius: '8px',
            textTransform: 'none',
            '&:hover': {
              backgroundColor: `${theme.palette.primary.main}10`
            },
            width: isMobile ? '100%' : 'auto'
          }}
        >
          Refresh
        </Button>
      </Box>
    </Box>
  );
}

export default HeaderSection; 