import React, { useState } from 'react';
import { Paper, Box, Typography, Alert, Grid, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// Import icons
import FlightIcon from '@mui/icons-material/Flight';
import PaidIcon from '@mui/icons-material/Paid';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import EventIcon from '@mui/icons-material/Event';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import UpdateIcon from '@mui/icons-material/Update';
import ImportExportIcon from '@mui/icons-material/ImportExport';

// Import formatters
import { formatCurrency, formatDateTime, formatImportMethod } from './utils/formatters';

// Import sections
import HeaderSection from './sections/HeaderSection';
import StatusSection from './sections/StatusSection';
import FlightInfoSection from './sections/FlightInfoSection';
import PassengerInfoSection from './sections/PassengerInfoSection';
import PricingInfoSection from './sections/PricingInfoSection';
import UpgradeOffersSection from './sections/UpgradeOffersSection';

// Import dialogs
import TrackingDialog from './dialogs/TrackingDialog';
import RefreshDialog from './dialogs/RefreshDialog';

function ReservationDisplayComponent({ reservationData, onError, onRefresh }) {
  const theme = useTheme();
  const [isHovered, setIsHovered] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [openTrackingDialog, setOpenTrackingDialog] = useState(false);
  const [refreshStatus, setRefreshStatus] = useState(null);

  if (!reservationData) {
    return <Typography>No reservation data available</Typography>;
  }

  return (
    <Paper 
      elevation={3} 
      sx={{ 
        p: 2, 
        mb: 2, 
        border: '2px solid #1976d2',
        transition: 'box-shadow 0.3s',
        boxShadow: isHovered ? '0 0 10px 0 #1976d2' : 'none',
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <HeaderSection 
        reservationData={reservationData} 
        onOpenRefreshDialog={() => setOpenConfirmDialog(true)}
      />
      
      <StatusSection 
        reservationData={reservationData}
        onOpenTrackingDialog={() => setOpenTrackingDialog(true)}
      />

      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Paper elevation={0} sx={{
            p: 2.5,
            borderRadius: '12px',
            backgroundColor: 'rgba(25, 118, 210, 0.04)',
            border: '1px solid rgba(25, 118, 210, 0.12)',
            height: '100%'
          }}>
            <Stack spacing={2}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <FlightIcon sx={{ color: theme.palette.primary.main }} />
                <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                  {reservationData.airline?.toUpperCase()}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <PaidIcon sx={{ color: theme.palette.success.main }} />
                <Typography>
                  <Box component="span" sx={{ color: 'text.secondary' }}>Total Payment:</Box>
                  <Box component="span" sx={{ ml: 1, fontWeight: 600 }}>
                    {formatCurrency(reservationData.payment_amount)}
                  </Box>
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <ConfirmationNumberIcon sx={{ color: theme.palette.secondary.main }} />
                <Typography>
                  <Box component="span" sx={{ color: 'text.secondary' }}>Ticket Number:</Box>
                  <Box component="span" sx={{ ml: 1, fontFamily: 'monospace' }}>{reservationData.ticket_number}</Box>
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <EventIcon sx={{ color: theme.palette.error.main }} />
                <Typography>
                  <Box component="span" sx={{ color: 'text.secondary' }}>Issue Date:</Box>
                  <Box component="span" sx={{ ml: 1 }}>{formatDateTime(reservationData.ticket_issue_date)}</Box>
                </Typography>
              </Box>
            </Stack>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper elevation={0} sx={{
            p: 2.5,
            borderRadius: '12px',
            backgroundColor: 'rgba(25, 118, 210, 0.04)',
            border: '1px solid rgba(25, 118, 210, 0.12)',
            height: '100%'
          }}>
            <Stack spacing={2}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <AccessTimeIcon sx={{ color: theme.palette.info.main }} />
                <Typography>
                  <Box component="span" sx={{ color: 'text.secondary' }}>Date Added:</Box>
                  <Box component="span" sx={{ ml: 1 }}>{formatDateTime(reservationData.created_at)}</Box>
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <UpdateIcon sx={{ color: theme.palette.success.main }} />
                <Typography>
                  <Box component="span" sx={{ color: 'text.secondary' }}>Last Refreshed:</Box>
                  <Box component="span" sx={{ ml: 1 }}>{formatDateTime(reservationData.last_refreshed_datetime)}</Box>
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <ImportExportIcon sx={{ color: theme.palette.warning.main }} />
                <Typography>
                  <Box component="span" sx={{ color: 'text.secondary' }}>Import Method:</Box>
                  <Box component="span" sx={{ ml: 1 }}>{formatImportMethod(reservationData.import_method)}</Box>
                </Typography>
              </Box>
            </Stack>
          </Paper>
        </Grid>
      </Grid>

      <FlightInfoSection reservationData={reservationData} />
      <PassengerInfoSection reservationData={reservationData} />
      <PricingInfoSection 
        reservationData={reservationData}
        onRefresh={onRefresh}
      />
      <UpgradeOffersSection 
        reservationData={reservationData}
        reservationId={reservationData?.reservation_id || reservationData?.id}
      />

      <RefreshDialog 
        open={openConfirmDialog}
        onClose={() => setOpenConfirmDialog(false)}
        reservationData={reservationData}
        onRefresh={onRefresh}
        setRefreshStatus={setRefreshStatus}
      />

      <TrackingDialog
        open={openTrackingDialog}
        onClose={() => setOpenTrackingDialog(false)}
        reservationData={reservationData}
        onRefresh={onRefresh}
      />

      {refreshStatus && (
        <Box sx={{ position: 'fixed', top: 24, right: 24, zIndex: 9999 }}>
          <Alert 
            severity={refreshStatus === 'success' ? 'success' : 'error'}
            onClose={() => setRefreshStatus(null)}
          >
            {refreshStatus === 'success' 
              ? 'Reservation refreshed successfully!' 
              : 'Failed to refresh reservation. Please try again.'}
          </Alert>
        </Box>
      )}
    </Paper>
  );
}

export default ReservationDisplayComponent; 