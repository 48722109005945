// /Users/sam/Desktop/autopilot-admin-app/src/App.js

import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Box } from '@mui/material';
import UserTable from './components/UserTable';
import ReservationLookup from './components/ReservationLookup';
import PassengerLookup from './components/PassengerLookup';
import RecordRefaring from './components/RecordRefaring';
import NavMenu from './components/NavMenu';
import HomePage from './components/HomePage';
import Login from './components/Login';
import PnrLookup from './components/PnrLookup';
import CreditInfoLookup from './components/CreditInfoLookup'; // New import
import ProtectedRoute from './components/ProtectedRoute';
import UserDetailPage from './components/UserDetailPage';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const token = document.cookie.replace(/(?:(?:^|.*;\s*)token\s*=\s*([^;]*).*$)|^.*$/, "$1");
    setIsAuthenticated(!!token);
  }, []);

  const handleLogin = () => {
    setIsAuthenticated(true);
  };

  const handleLogout = () => {
    document.cookie = 'token=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    setIsAuthenticated(false);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Router>
        {isAuthenticated && <NavMenu onLogout={handleLogout} />}
        <Box sx={{ padding: 2 }}>
          <Routes>
            <Route
              path="/login"
              element={
                <ProtectedRoute>
                  {!isAuthenticated ? <Login onLogin={handleLogin} /> : <Navigate to="/" />}
                </ProtectedRoute>
              }
            />
            <Route
              path="/"
              element={
                <ProtectedRoute>
                  <HomePage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/users"
              element={
                <ProtectedRoute>
                  <UserTable />
                </ProtectedRoute>
              }
            />
            <Route
              path="/reservation"
              element={
                <ProtectedRoute>
                  <ReservationLookup />
                </ProtectedRoute>
              }
            />
            <Route
              path="/passenger"
              element={
                <ProtectedRoute>
                  <PassengerLookup />
                </ProtectedRoute>
              }
            />
            <Route
              path="/record-refaring"
              element={
                <ProtectedRoute>
                  <RecordRefaring />
                </ProtectedRoute>
              }
            />
            <Route
              path="/pnr-lookup"
              element={
                <ProtectedRoute>
                  <PnrLookup />
                </ProtectedRoute>
              }
            />
            <Route
              path="/credit-info-lookup"
              element={
                <ProtectedRoute>
                  <CreditInfoLookup />
                </ProtectedRoute>
              }
            />
            <Route
              path="/User"
              element={
                <ProtectedRoute>
                  <UserDetailPage />
                </ProtectedRoute>
              }
            />
          </Routes>
        </Box>
      </Router>
    </LocalizationProvider>
  );
}

export default App;
