import React, { useState, useCallback, useEffect } from 'react';
import {
  Typography,
  Paper,
  Grid,
  Box,
  Chip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Card,
  CardContent,
  Checkbox,
  Divider,
  Stepper,
  Step,
  StepLabel,
  IconButton,
  Tooltip,
  Button,
  MenuItem,
  Menu,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Alert,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Switch,
  FormControlLabel,
  FormGroup,
  Tabs,
  Tab,
  Avatar,
} from '@mui/material';
import { LineChart } from '@mui/x-charts/LineChart';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import FlightLandIcon from '@mui/icons-material/FlightLand';
import AirplaneTicketIcon from '@mui/icons-material/AirplaneTicket';
import EventIcon from '@mui/icons-material/Event';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import InfoIcon from '@mui/icons-material/Info';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import Cookies from 'js-cookie';
import { useTheme, createTheme, ThemeProvider } from '@mui/material/styles';
import { formatInTimeZone } from 'date-fns-tz';
import { parseISO, format, isValid } from 'date-fns'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import FlightIcon from '@mui/icons-material/Flight';
import PaidIcon from '@mui/icons-material/Paid';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import UpdateIcon from '@mui/icons-material/Update';
import { alpha } from '@mui/material/styles';
import ReceiptIcon from '@mui/icons-material/Receipt';
import FlightClassIcon from '@mui/icons-material/FlightClass';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import PersonIcon from '@mui/icons-material/Person';
import StarIcon from '@mui/icons-material/Star';
import SecurityIcon from '@mui/icons-material/Security';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import GppGoodIcon from '@mui/icons-material/GppGood';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import '../styles/fonts.css';

dayjs.extend(utc);
dayjs.extend(timezone);

const LOYALTY_TIER_STYLES = {
  SILVER: {
    bg: alpha('#C0C0C0', 0.15),
    color: '#808080',
    gradient: 'linear-gradient(45deg, #C0C0C0, #E8E8E8, #C0C0C0)'
  },
  GOLD: {
    bg: alpha('#FFD700', 0.15),
    color: '#B8860B',
    gradient: 'linear-gradient(45deg, #FFD700, #FDB931, #FFD700)'
  },
  PLATINUM: {
    bg: alpha('#E5E4E2', 0.15),
    color: '#666666',
    gradient: 'linear-gradient(45deg, #CCD1D9, #E5E4E2, #95A5A6)',
    textColor: '#2C3E50'
  },
  TOP_TIER: {
    bg: alpha('#000000', 0.1),
    color: '#000000',
    gradient: 'linear-gradient(90deg, #BF953F, #FCF6BA, #B38728, #FBF5B7, #AA771C)',
    shimmer: true
  },
  INVITE_ONLY: {
    bg: alpha('#000000', 0.1),
    color: '#000000',
    gradient: 'linear-gradient(90deg, #0A0A0A, #1A1A1A, #000000)',
    shimmer: true,
    borderGradient: 'linear-gradient(90deg, #2C3E50, #000000, #2C3E50)'
  },
  DEFAULT: {  // Add a default style
    bg: alpha('#808080', 0.15),
    color: '#808080',
    gradient: 'linear-gradient(45deg, #808080, #A9A9A9, #808080)'
  }
};

const getLoyaltyStyle = (loyaltyInfo) => {
  if (!loyaltyInfo || !loyaltyInfo.status) {
    return LOYALTY_TIER_STYLES.DEFAULT;
  }
  return LOYALTY_TIER_STYLES[loyaltyInfo.status] || LOYALTY_TIER_STYLES.DEFAULT;
};

const CabinSelector = ({ cabins, selectedCabins, onChange }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleToggleAll = () => {
    if (selectedCabins.length === cabins.length) {
      onChange([]);
    } else {
      onChange([...cabins]);
    }
  };

  const handleToggleCabin = (cabin) => {
    const newSelection = selectedCabins.includes(cabin)
      ? selectedCabins.filter(c => c !== cabin)
      : [...selectedCabins, cabin];
    onChange(newSelection);
  };

  return (
    <Box>
      <Button
        variant="outlined"
        onClick={handleClick}
        endIcon={<ExpandMoreIcon />}
        size="small"
      >
        Select Cabins ({selectedCabins.length})
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: { maxHeight: 300 }
        }}
      >
        <MenuItem onClick={handleToggleAll}>
          <Checkbox
            checked={selectedCabins.length === cabins.length}
            indeterminate={selectedCabins.length > 0 && selectedCabins.length < cabins.length}
          />
          <ListItemText primary="Toggle All" />
        </MenuItem>
        <Divider />
        {cabins.map((cabin) => (
          <MenuItem key={cabin} onClick={() => handleToggleCabin(cabin)}>
            <Checkbox checked={selectedCabins.includes(cabin)} />
            <ListItemText primary={cabin} />
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

// Add airline logo constants
const AIRLINE_LOGOS = {
  delta: 'https://cdn.prod.website-files.com/660c98c8b15b9e84551e4553/660ca876659da2dcd1f270e6_unnamed.webp',
  aa: 'https://cdn.prod.website-files.com/660c98c8b15b9e84551e4553/660d5ea1eff61f9aceefc7bf_unnamed%20(1).webp',
  ua: 'https://cdn.prod.website-files.com/660c98c8b15b9e84551e4553/660d5ed83484fc0fb471306a_unnamed%20(2).webp'
};

const getAirlineLogo = (airline) => {
  if (!airline) return null;
  const airlineKey = airline.toLowerCase();
  if (airlineKey.includes('delta')) return AIRLINE_LOGOS.delta;
  if (airlineKey.includes('american') || airlineKey === 'aa') return AIRLINE_LOGOS.aa;
  if (airlineKey.includes('united') || airlineKey === 'ua') return AIRLINE_LOGOS.ua;
  return null;
};

const theme = createTheme({
  palette: {
    primary: {
      main: '#535aff',
    },
  },
  typography: {
    fontFamily: '"Haffer Trial", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
    h1: {
      fontFamily: '"Haffer Trial", sans-serif',
      fontWeight: 700,
    },
    h2: {
      fontFamily: '"Haffer Trial", sans-serif',
      fontWeight: 700,
    },
    h3: {
      fontFamily: '"Haffer Trial", sans-serif',
      fontWeight: 600,
    },
    h4: {
      fontFamily: '"Haffer Trial", sans-serif',
      fontWeight: 600,
    },
    h5: {
      fontFamily: '"Haffer Trial", sans-serif',
      fontWeight: 500,
    },
    h6: {
      fontFamily: '"Haffer Trial", sans-serif',
      fontWeight: 500,
    },
    subtitle1: {
      fontFamily: '"Haffer Trial", sans-serif',
      fontWeight: 500,
    },
    subtitle2: {
      fontFamily: '"Haffer Trial", sans-serif',
      fontWeight: 500,
    },
    body1: {
      fontFamily: '"Haffer Trial", sans-serif',
      fontWeight: 400,
    },
    body2: {
      fontFamily: '"Haffer Trial", sans-serif',
      fontWeight: 400,
    },
    button: {
      fontFamily: '"Haffer Trial", sans-serif',
      fontWeight: 500,
    },
    caption: {
      fontFamily: '"Haffer Trial", sans-serif',
      fontWeight: 400,
    },
    overline: {
      fontFamily: '"Haffer Trial", sans-serif',
      fontWeight: 400,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Haffer Trial';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: url('https://haffer-trial-font.s3.us-east-2.amazonaws.com/Haffer-TRIAL-Regular.woff') format('woff');
        }
      `,
    },
  },
});

function ReservationDisplayComponent({ reservationData, onError, onRefresh }) {
  const navigate = useNavigate();
  const baseTheme = useTheme();
  const [isHovered, setIsHovered] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [lastRefreshedPrice, setLastRefreshedPrice] = useState(reservationData?.last_refreshed_price);
  const [lastRefreshedDateTime, setLastRefreshedDateTime] = useState(reservationData?.last_refreshed_datetime);
  const [justRefreshed, setJustRefreshed] = useState(false);
  const [hoveredRow, setHoveredRow] = useState(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [refreshStatus, setRefreshStatus] = useState(null);
  const [isRefreshingReservation, setIsRefreshingReservation] = useState(false);
  const [copiedField, setCopiedField] = useState(null);
  const [selectedCabins, setSelectedCabins] = useState(
    reservationData?.upgrade_offer_info?.upgradeable_cabins || []
  );
  const [isRefreshingUpgrades, setIsRefreshingUpgrades] = useState(false);
  const [isInitializingUpgrades, setIsInitializingUpgrades] = useState(false);
  const [upgradeActionStatus, setUpgradeActionStatus] = useState(null);
  const [openTrackingDialog, setOpenTrackingDialog] = useState(false);
  const [trackingStatuses, setTrackingStatuses] = useState({
    is_active: reservationData?.is_active || false,
    is_archived: reservationData?.is_archived || false,
    is_auto_rebook_enabled: reservationData?.is_auto_rebook_enabled || false,
    is_tracked: reservationData?.is_tracked || false,
    is_upcoming: reservationData?.is_upcoming || false,
    is_visible: reservationData?.is_visible || false,
  });
  const [isUpdatingStatus, setIsUpdatingStatus] = useState(false);
  const [shouldAnimate, setShouldAnimate] = useState(true);

  useEffect(() => {
    if (reservationData?.upgrade_offer_info?.upgradeable_cabins) {
      setSelectedCabins(reservationData.upgrade_offer_info.upgradeable_cabins);
    }
  }, [reservationData]);

  useEffect(() => {
    if (!reservationData) {
      onError(new Error('No reservation data available'));
      return;
    }
    if (!reservationData.trips) {
      onError(new Error('No trip information available'));
      return;
    }
  }, [reservationData, onError]);

  useEffect(() => {
    // Initial animation
    setShouldAnimate(true);
    const timer = setInterval(() => {
      setShouldAnimate(true);
      // Reset animation flag after animation completes
      setTimeout(() => setShouldAnimate(false), 2000);
    }, 10000);

    return () => clearInterval(timer);
  }, []);

  const formatDateTime = (dateTimeString) => {
    if (!dateTimeString) return 'N/A';
    try {
      const date = parseISO(dateTimeString);
      return format(date, 'MMM d, yyyy h:mm a');
    } catch (error) {
      console.error('Error parsing date:', error);
      return 'Invalid Date';
    }
  };

  const formatDate = (dateString) => {
    return dateString ? dayjs(dateString).format('MMMM D, YYYY') : 'N/A';
  };

  const formatCurrency = (value, isAward = false) => {
    const number = parseFloat(value);
    if (isNaN(number)) {
      return isAward ? '0 Miles' : `${reservationData.currency_symbol || '$'}0.00`;
    }
    
    if (isAward) {
      return `${number.toLocaleString()} Miles`;
    } else {
      const symbol = reservationData.currency_symbol || '$';
      return `${symbol}${number.toFixed(2)}`;
    }
  };

  const formatAwardPrice = () => {
    const awardPrice = parseFloat(reservationData.award_price);
    const taxesAndFees = parseFloat(reservationData.payment_amount);
    
    if (isNaN(awardPrice)) {
      return 'N/A';
    }

    const milesText = `${awardPrice.toLocaleString()} Miles`;
    
    if (!isNaN(taxesAndFees) && taxesAndFees > 0) {
      return milesText;
    } else {
      return milesText;
    }
  };

  const formatToEST = (dateTimeString) => {
    return dateTimeString ? dayjs(dateTimeString).tz('America/New_York').format('MMMM D, YYYY h:mm A z') : 'N/A';
  };

  const renderChip = (label, value) => (
    <Chip 
      label={`${label}: ${value ? 'Yes' : 'No'}`} 
      color={value ? "primary" : "default"} 
      variant={value ? "filled" : "outlined"}
    />
  );

  const refreshPrice = useCallback(async () => {
    setIsRefreshing(true);
    try {
      const token = Cookies.get('token');
      const response = await fetch('https://dev-api.withautopilot.com/admin_portal/refresh_reservation_price', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'token': token,
        },
        body: JSON.stringify({ reservation_id: reservationData.reservation_id }),
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setLastRefreshedPrice(data.price.toString());
      setLastRefreshedDateTime(new Date().toISOString());
      setJustRefreshed(true);
    } catch (error) {
      console.error('Error refreshing price:', error);
      onError(new Error('Failed to refresh price'));
    } finally {
      setIsRefreshing(false);
    }
  }, [reservationData?.reservation_id, onError]);

  const handleRefreshReservation = async () => {
    setIsRefreshingReservation(true);
    try {
      const token = Cookies.get('token');
      const response = await fetch('https://dev-api.withautopilot.com/admin_portal/refresh_pnr', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'token': token,
        },
        body: JSON.stringify({
          user_id: reservationData.user_id,
          reservation_id: reservationData.reservation_id
        }),
      });

      console.log('Response status:', response.status);
      const responseText = await response.text();
      console.log('Response text:', responseText);

      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.status} ${response.statusText}`);
      }

      let data;
      try {
        data = JSON.parse(responseText);
      } catch (error) {
        console.error('Error parsing JSON:', error);
        throw new Error('Invalid JSON response from server');
      }

      console.log('Parsed response data:', data);

      if (data && (data.response === "Complete" || data.response === "complete")) {
        console.log('Refresh successful');
        setRefreshStatus('success');
        if (typeof onRefresh === 'function') {
          onRefresh();
        } else {
          console.warn('onRefresh is not a function. Unable to refresh data.');
        }
      } else {
        console.log('Unexpected response from server');
        throw new Error('Unexpected response from server');
      }
    } catch (error) {
      console.error('Error refreshing reservation:', error);
      setRefreshStatus('error');
    } finally {
      setIsRefreshingReservation(false);
      setOpenConfirmDialog(false);
    }
  };

  const handleCopy = (text, field) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopiedField(field);
      setTimeout(() => setCopiedField(null), 2000); // Reset after 2 seconds
    });
  };

  const renderCopyableField = (label, value, field) => (
    <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <strong>{label}:</strong> {value}
      <Tooltip title={copiedField === field ? "Copied!" : "Copy to clipboard"}>
        <IconButton size="small" onClick={() => handleCopy(value, field)}>
          <ContentCopyIcon fontSize="small" />
        </IconButton>
      </Tooltip>
    </Typography>
  );

  const renderFlightCard = (trip, index) => {
    if (!trip) return null;
    return (
      <Card key={index} sx={{ mb: 2, boxShadow: 3 }}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Trip {index + 1}: {trip.departure_airport} to {trip.arrival_airport}
            <Typography variant="caption" sx={{ ml: 1, color: 'text.secondary' }}>
              (Trip ID: {trip.flight_id})
            </Typography>
          </Typography>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={5}>
              <Box display="flex" alignItems="center">
                <FlightTakeoffIcon color="primary" sx={{ mr: 1 }} />
                <Typography variant="subtitle1">
                  {trip.departure_airport}
                </Typography>
              </Box>
              <Typography variant="body2" color="textSecondary">
                <EventIcon fontSize="small" sx={{ mr: 0.5, verticalAlign: 'middle' }} />
                {formatDateTime(trip.departure_datetime, trip.departure_datetime_local)}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Divider>
                <AirplaneTicketIcon color="action" />
              </Divider>
            </Grid>
            <Grid item xs={5}>
              <Box display="flex" alignItems="center" justifyContent="flex-end">
                <Typography variant="subtitle1">
                  {trip.arrival_airport}
                </Typography>
                <FlightLandIcon color="primary" sx={{ ml: 1 }} />
              </Box>
              <Typography variant="body2" color="textSecondary" align="right">
                <EventIcon fontSize="small" sx={{ mr: 0.5, verticalAlign: 'middle' }} />
                {formatDateTime(trip.arrival_datetime, trip.arrival_datetime_local)}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Flight Details</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Stepper orientation="vertical">
              {trip.segments && trip.segments.map((segment, segIndex) => (
                <Step key={segIndex} active={true}>
                  <StepLabel>
                    <Typography variant="subtitle1" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      Flight {segment.flight_number}: {segment.departure_airport} to {segment.arrival_airport}
                      <Tooltip title={copiedField === `flight_${index}_${segIndex}` ? "Copied!" : "Copy flight number"}>
                        <IconButton 
                          size="small" 
                          onClick={() => handleCopy(segment.flight_number.replace(/\s+/g, ''), `flight_${index}_${segIndex}`)}
                        >
                          <ContentCopyIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                      <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                        (Segment ID: {segment.segment_id})
                      </Typography>
                    </Typography>
                  </StepLabel>
                  <Box sx={{ ml: 3, mt: 1, mb: 2 }}>
                    <Typography variant="body2">
                      <strong>Departure:</strong> {formatDateTime(segment.departure_datetime, segment.departure_datetime_local)}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Arrival:</strong> {formatDateTime(segment.arrival_datetime, segment.arrival_datetime_local)}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Aircraft:</strong> {segment.aircraft}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Cabin:</strong> {segment.cabin_name}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Seat:</strong> {segment.seat && segment.seat !== "{NULL}" ? segment.seat : 'Not assigned'}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Fare Class:</strong> {segment.fare_class?.trim()}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Fare Code:</strong> {segment.fare_code}
                    </Typography>
                    {segment.flight_status && (
                      <Typography variant="body2">
                        <strong>Flight Status:</strong> {segment.flight_status}
                      </Typography>
                    )}
                    {segment.remarks && (
                      <Typography variant="body2">
                        <strong>Remarks:</strong> {segment.remarks}
                      </Typography>
                    )}
                  </Box>
                </Step>
              ))}
            </Stepper>
          </AccordionDetails>
        </Accordion>
      </Card>
    );
  };

  const renderHistoricalPriceChart = () => {
    if (!reservationData.price_history || reservationData.price_history.length === 0) {
      return (
        <Paper 
          elevation={0} 
          sx={{ 
            p: 4,
            textAlign: 'center',
            border: `1px dashed ${theme.palette.divider}`,
            borderRadius: 2,
            bgcolor: alpha(theme.palette.primary.main, 0.02),
            height: 300,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 2
          }}
        >
          <Box sx={{ 
            width: 48, 
            height: 48, 
            borderRadius: '50%',
            bgcolor: alpha(theme.palette.primary.main, 0.1),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
            <ImportExportIcon color="primary" />
          </Box>
          <Typography variant="subtitle1" color="text.secondary">
            No price history available yet
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ maxWidth: 400, mb: 2 }}>
            {reservationData.is_upcoming 
              ? 'Start tracking price changes by refreshing the current price'
              : 'This reservation is in the past and price tracking is no longer available'}
          </Typography>
          <Tooltip title={!reservationData.is_upcoming ? "You can't refresh a price of a previous reservation" : ""}>
            <span>
              <Button
                variant="contained"
                color="primary"
                startIcon={isRefreshing ? <CircularProgress size={20} color="inherit" /> : <RefreshIcon />}
                onClick={refreshPrice}
                disabled={isRefreshing || !reservationData.is_upcoming}
                sx={{ 
                  borderRadius: '20px',
                  px: 3,
                  boxShadow: 'none',
                  '&:hover': {
                    boxShadow: 'none',
                    bgcolor: alpha(theme.palette.primary.main, 0.8)
                  },
                  '&.Mui-disabled': {
                    bgcolor: theme.palette.action.disabledBackground,
                    color: theme.palette.action.disabled
                  }
                }}
              >
                {isRefreshing ? 'Refreshing Price...' : 'Refresh Current Price'}
              </Button>
            </span>
          </Tooltip>
        </Paper>
      );
    }
  
    const data = reservationData.price_history.map(entry => ({
      date: new Date(entry.timestamp),
      price: parseFloat(entry.price)
    }));
  
    // Filter out any invalid data points
    const validData = data.filter(point => !isNaN(point.date.getTime()) && !isNaN(point.price));
  
    if (validData.length === 0) {
      return (
        <Typography variant="body1" align="center">
          Can't display historical data
        </Typography>
      );
    }
  
    const minPrice = Math.min(...validData.map(d => d.price));
    const maxPrice = Math.max(...validData.map(d => d.price));
  
    return (
      <Box sx={{ width: '100%', height: 300 }}>
        <LineChart
          dataset={validData}
          xAxis={[{ 
            dataKey: 'date',
            scaleType: 'time',
            valueFormatter: (value) => dayjs(value).format('MMM D, YYYY'),
          }]}
          yAxis={[{ 
            dataKey: 'price',
            scaleType: 'linear',
            valueFormatter: (value) => `$${value.toFixed(2)}`,
            min: Math.floor(minPrice * 0.9),  // Add some padding
            max: Math.ceil(maxPrice * 1.1),   // Add some padding
          }]}
          series={[
            {
              dataKey: 'price',
              area: true,
              color: theme.palette.primary.main,
              areaOpacity: 0.5,
              valueFormatter: (value) => `$${value.toFixed(2)}`,
            },
          ]}
          height={300}
          margin={{ top: 10, bottom: 30, left: 60, right: 10 }}
          slotProps={{
            legend: {
              hidden: true
            }
          }}
          tooltip={{
            trigger: 'axis',
            formatter: (params) => {
              const dataPoint = params[0];
              const date = new Date(dataPoint.axisValue);
              const formattedDate = formatInTimeZone(date, 'America/New_York', 'MMM d, yyyy HH:mm:ss zzz');
              return `${formattedDate}<br/>Price: $${dataPoint.value.toFixed(2)}`;
            }
          }}
        />
      </Box>
    );
  };

  const renderHistoricalPriceTable = () => {
    if (!reservationData.price_history || reservationData.price_history.length === 0) {
      return (
        <Paper 
          elevation={0} 
          sx={{ 
            p: 4,
            textAlign: 'center',
            border: `1px dashed ${theme.palette.divider}`,
            borderRadius: 2,
            bgcolor: alpha(theme.palette.primary.main, 0.02)
          }}
        >
          <Stack spacing={2} alignItems="center">
            <Box sx={{ 
              width: 48, 
              height: 48, 
              borderRadius: '50%',
              bgcolor: alpha(theme.palette.primary.main, 0.1),
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
              <ReceiptIcon color="primary" />
            </Box>
            <Typography variant="subtitle1" color="text.secondary">
              No price history records yet
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ maxWidth: 400 }}>
              {reservationData.is_upcoming 
                ? 'Price history will be recorded each time you refresh the current price'
                : 'This reservation is in the past and price history is no longer being tracked'}
            </Typography>
            <Tooltip title={!reservationData.is_upcoming ? "You can't refresh a price of a previous reservation" : ""}>
              <span>
                <Button
                  variant="outlined"
                  color="primary"
                  startIcon={isRefreshing ? <CircularProgress size={20} color="inherit" /> : <RefreshIcon />}
                  onClick={refreshPrice}
                  disabled={isRefreshing || !reservationData.is_upcoming}
                  sx={{ 
                    borderRadius: '20px',
                    px: 3,
                    '&:hover': {
                      bgcolor: alpha(theme.palette.primary.main, 0.05)
                    },
                    '&.Mui-disabled': {
                      borderColor: theme.palette.action.disabled,
                      color: theme.palette.action.disabled
                    }
                  }}
                >
                  {isRefreshing ? 'Refreshing Price...' : 'Refresh Current Price'}
                </Button>
              </span>
            </Tooltip>
          </Stack>
        </Paper>
      );
    }

    return (
      <TableContainer component={Paper} sx={{ maxHeight: 250 }}>
        <Table stickyHeader aria-label="historical price table">
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell align="right">Price</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {reservationData.price_history.map((row, index) => (
              <TableRow 
                key={index}
                sx={{ 
                  '&:nth-of-type(odd)': { backgroundColor: 'action.hover' },
                  '&:hover': { backgroundColor: 'action.selected' },
                  ...(hoveredRow === index && { backgroundColor: 'primary.light' }),
                }}
                onMouseEnter={() => setHoveredRow(index)}
                onMouseLeave={() => setHoveredRow(null)}
              >
                <TableCell component="th" scope="row">
                  {formatDateTime(row.timestamp)}
                </TableCell>
                <TableCell align="right">{formatCurrency(row.price)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const formatImportMethod = (method) => {
    if (!method) return 'N/A';
    return method.charAt(0).toUpperCase() + method.slice(1).toLowerCase();
  };

  const getPriceDifference = (current, last) => {
    const currentPrice = parseFloat(current);
    const lastPrice = parseFloat(last);
    if (isNaN(currentPrice) || isNaN(lastPrice)) return null;
    return currentPrice - lastPrice;
  };

  const priceDifference = getPriceDifference(reservationData.current_effective_price, lastRefreshedPrice);
  const isPriceLower = priceDifference > 0;

  const greenTextStyle = {
    color: theme.palette.success.main,
    fontWeight: 'bold',
  };

  const handleRefreshUpgradeOffers = async () => {
    setIsRefreshingUpgrades(true);
    try {
      const token = Cookies.get('token');
      const response = await fetch('https://dev-api.withautopilot.com/admin_portal/refresh_upgrade_offers', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'token': token,
        },
        body: JSON.stringify({
          reservation_id: reservationData.reservation_id
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to refresh upgrade offers');
      }

      const data = await response.json();
      if (data.success) {
        setUpgradeActionStatus({ type: 'success', message: 'Upgrade offers refreshed successfully' });
        if (typeof onRefresh === 'function') {
          onRefresh();
        }
      }
    } catch (error) {
      console.error('Error refreshing upgrade offers:', error);
      setUpgradeActionStatus({ type: 'error', message: 'Failed to refresh upgrade offers' });
    } finally {
      setIsRefreshingUpgrades(false);
      setTimeout(() => setUpgradeActionStatus(null), 3000);
    }
  };

  const handleInitializeUpgradeOffers = async () => {
    setIsInitializingUpgrades(true);
    try {
      const token = Cookies.get('token');
      const response = await fetch('https://dev-api.withautopilot.com/admin_portal/initialize_upgrade_offers', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'token': token,
        },
        body: JSON.stringify({
          reservation_id: reservationData.reservation_id
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to initialize upgrade offers');
      }

      const data = await response.json();
      if (data.success) {
        setUpgradeActionStatus({ 
          type: 'success', 
          message: 'Upgrade offers initialized successfully' 
        });
        if (typeof onRefresh === 'function') {
          onRefresh();
        }
      }
    } catch (error) {
      console.error('Error initializing upgrade offers:', error);
      setUpgradeActionStatus({ 
        type: 'error', 
        message: 'Failed to initialize upgrade offers' 
      });
    } finally {
      setIsInitializingUpgrades(false);
      setTimeout(() => setUpgradeActionStatus(null), 3000);
    }
  };

  const prepareUpgradeOfferData = (offerHistory) => {
    if (!Array.isArray(offerHistory)) return [];
    
    const dataset = [];
    
    offerHistory.forEach(offer => {
      // Ensure we have a valid date
      const offerDate = new Date(offer.created_at);
      if (isNaN(offerDate.getTime())) {
        console.warn('Invalid date encountered:', offer.created_at);
        return;
      }

      const existingEntry = dataset.find(entry => 
        entry.date.getTime() === offerDate.getTime()
      );

      if (existingEntry) {
        if (offer.offer_type === 'cash') {
          existingEntry[`${offer.cabin_offered}_price`] = offer.price || null;
        } else {
          existingEntry[`${offer.cabin_offered}_miles`] = offer.miles_required || null;
        }
      } else {
        const newEntry = {
          date: offerDate,
        };
        if (offer.offer_type === 'cash') {
          newEntry[`${offer.cabin_offered}_price`] = offer.price || null;
        } else {
          newEntry[`${offer.cabin_offered}_miles`] = offer.miles_required || null;
        }
        dataset.push(newEntry);
      }
    });

    return dataset.sort((a, b) => a.date - b.date);
  };

  const handleUpdateTrackingStatus = async () => {
    setIsUpdatingStatus(true);
    try {
      const token = Cookies.get('token');
      const response = await fetch('https://dev-api.withautopilot.com/admin_portal/update_reservation_status', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'token': token,
        },
        body: JSON.stringify({
          reservation_id: reservationData.reservation_id,
          ...trackingStatuses
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to update tracking status');
      }

      if (typeof onRefresh === 'function') {
        onRefresh();
      }
      setOpenTrackingDialog(false);
    } catch (error) {
      console.error('Error updating tracking status:', error);
      onError(new Error('Failed to update tracking status'));
    } finally {
      setIsUpdatingStatus(false);
    }
  };

  const groupChips = (reservationData) => {
    return {
      tracking: [
        { label: 'Active', value: reservationData.is_active },
        { label: 'Tracked', value: reservationData.is_tracked },
        { label: 'Visible', value: reservationData.is_visible },
        { label: 'Archived', value: reservationData.is_archived },
        { label: 'Upcoming', value: reservationData.is_upcoming },
        { label: 'Auto Rebook', value: reservationData.is_auto_rebook_enabled },
      ],
      info: [
        { label: 'Award', value: reservationData.is_award },
        { label: 'Basic Economy', value: reservationData.is_basic_economy },
        { label: 'Elite Upgrade', value: reservationData.is_elite_upgrade },
        { label: 'Refundable', value: reservationData.is_refundable },
        { label: 'Direct Booking', value: reservationData.is_direct_booking },
      ]
    };
  };

  const renderStatusChips = () => {
    const groups = groupChips(reservationData);
    
    return (
      <Box sx={{ 
        mb: 2,
        display: 'flex',
        justifyContent: 'center',
        width: '100%'
      }}>
        <Paper 
          elevation={0}
          sx={{ 
            maxWidth: '900px',
            width: '100%',
            p: '20px 24px',
            borderRadius: '16px',
            bgcolor: 'background.paper',
            border: `1px solid ${theme.palette.divider}`,
            position: 'relative'
          }}
        >
          {/* Edit Button */}
          <IconButton
            onClick={() => setOpenTrackingDialog(true)}
            size="small"
            sx={{ 
              position: 'absolute',
              top: 16,
              right: 16,
              color: theme.palette.text.secondary,
              padding: '4px',
              '&:hover': {
                color: theme.palette.primary.main,
                backgroundColor: 'rgba(25, 118, 210, 0.04)'
              }
            }}
          >
            <EditIcon sx={{ fontSize: 16 }} />
          </IconButton>

          <Stack spacing={2.5} width="100%">
            {/* Tracking Status */}
            <Box>
              <Typography 
                variant="subtitle2" 
                sx={{ 
                  fontWeight: 500,
                  color: theme.palette.text.secondary,
                  fontSize: '0.875rem',
                  mb: 1.25,
                  textAlign: 'center'
                }}
              >
                Tracking Status
              </Typography>
              <Box sx={{ 
                display: 'flex', 
                flexWrap: 'wrap', 
                gap: '6px',
                justifyContent: 'center'
              }}>
                {groups.tracking.map((item, index) => (
                  <Button
                    key={index}
                    size="small"
                    disableElevation
                    sx={{ 
                      minWidth: 'auto',
                      padding: '4px 12px',
                      borderRadius: '20px',
                      backgroundColor: item.value ? theme.palette.primary.main : 'transparent',
                      color: item.value ? 'white' : theme.palette.text.secondary,
                      border: item.value ? 'none' : `1px solid ${theme.palette.divider}`,
                      '&:hover': {
                        backgroundColor: item.value ? theme.palette.primary.dark : 'rgba(0, 0, 0, 0.04)',
                      },
                      textTransform: 'none',
                      fontSize: '0.75rem',
                      fontWeight: 500,
                      height: '26px',
                      lineHeight: 1,
                      boxShadow: item.value ? '0 2px 4px rgba(25, 118, 210, 0.16)' : 'none',
                      transition: 'all 0.2s ease-in-out',
                      '&:active': {
                        transform: 'scale(0.98)'
                      }
                    }}
                  >
                    {item.label}
                  </Button>
                ))}
              </Box>
            </Box>

            {/* Reservation Type */}
            <Box>
              <Typography 
                variant="subtitle2" 
                sx={{ 
                  fontWeight: 500,
                  color: theme.palette.text.secondary,
                  fontSize: '0.875rem',
                  mb: 1.25,
                  textAlign: 'center'
                }}
              >
                Reservation Type
              </Typography>
              <Box sx={{ 
                display: 'flex', 
                flexWrap: 'wrap', 
                gap: '6px',
                justifyContent: 'center'
              }}>
                {groups.info.map((item, index) => (
                  <Button
                    key={index}
                    size="small"
                    disableElevation
                    sx={{ 
                      minWidth: 'auto',
                      padding: '4px 12px',
                      borderRadius: '20px',
                      backgroundColor: item.value ? theme.palette.error.main : 'transparent',
                      color: item.value ? 'white' : theme.palette.text.secondary,
                      border: item.value ? 'none' : `1px solid ${theme.palette.divider}`,
                      '&:hover': {
                        backgroundColor: item.value ? theme.palette.error.dark : 'rgba(0, 0, 0, 0.04)',
                      },
                      textTransform: 'none',
                      fontSize: '0.75rem',
                      fontWeight: 500,
                      height: '26px',
                      lineHeight: 1,
                      boxShadow: item.value ? '0 2px 4px rgba(211, 47, 47, 0.16)' : 'none',
                      transition: 'all 0.2s ease-in-out',
                      '&:active': {
                        transform: 'scale(0.98)'
                      }
                    }}
                  >
                    {item.label}
                  </Button>
                ))}
              </Box>
            </Box>
          </Stack>
        </Paper>
      </Box>
    );
  };

  const formatRoute = (segment) => {
    return `${segment.departure_airport} - ${segment.arrival_airport}`;
  };

  const UpgradeOffersSection = ({ reservationData }) => {
    const [selectedTab, setSelectedTab] = useState(0);

    const segments = reservationData?.upgrade_offer_info?.segments || [];

    const handleTabChange = (event, newValue) => {
      setSelectedTab(newValue);
    };

    if (!segments.length) {
      return (
        <Paper 
          elevation={0} 
          sx={{ 
            p: 4,
            textAlign: 'center',
            border: `1px dashed ${theme.palette.divider}`,
            borderRadius: 2,
            bgcolor: alpha(theme.palette.primary.main, 0.02)
          }}
        >
          <Stack spacing={2} alignItems="center">
            <Box sx={{ 
              width: 48, 
              height: 48, 
              borderRadius: '50%',
              bgcolor: alpha(theme.palette.primary.main, 0.1),
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
              <FlightClassIcon color="primary" />
            </Box>
            <Typography variant="subtitle1" color="text.secondary">
              No upgrade offers available yet
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ maxWidth: 400 }}>
              Initialize upgrade tracking to start monitoring available offers for your flight
            </Typography>
            <Box sx={{ mt: 2, display: 'flex', gap: 2 }}>
              <Tooltip title={!reservationData.is_upcoming ? "Cannot initialize upgrade offers for past reservations" : ""}>
                <span>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={isInitializingUpgrades ? <CircularProgress size={20} color="inherit" /> : <AddIcon />}
                    onClick={handleInitializeUpgradeOffers}
                    disabled={isInitializingUpgrades || !reservationData.is_upcoming}
                    sx={{ 
                      borderRadius: '20px',
                      px: 3,
                      boxShadow: 'none',
                      bgcolor: '#535aff',
                      '&:hover': {
                        boxShadow: 'none',
                        bgcolor: alpha('#535aff', 0.8)
                      },
                      '&.Mui-disabled': {
                        bgcolor: theme.palette.action.disabledBackground,
                        color: theme.palette.action.disabled
                      }
                    }}
                  >
                    {isInitializingUpgrades ? 'Initializing...' : 'Initialize Upgrade Offers'}
                  </Button>
                </span>
              </Tooltip>
              {reservationData.upgrade_offer_info?.upgradeable_cabins?.length > 0 && (
                <Button
                  variant="outlined"
                  color="primary"
                  startIcon={<RefreshIcon />}
                  onClick={handleRefreshUpgradeOffers}
                  disabled={isRefreshingUpgrades}
                  sx={{ 
                    borderRadius: '20px',
                    px: 3,
                    borderColor: '#535aff',
                    color: '#535aff',
                    '&:hover': {
                      bgcolor: alpha('#535aff', 0.05),
                      borderColor: '#535aff'
                    }
                  }}
                >
                  {isRefreshingUpgrades ? 'Checking...' : 'Check for Upgrades'}
                </Button>
              )}
            </Box>
            {upgradeActionStatus && (
              <Paper
                elevation={0}
                sx={{
                  mt: 2,
                  p: 2,
                  bgcolor: alpha(
                    theme.palette[upgradeActionStatus.type === 'success' ? 'success' : 'error'].main, 
                    0.05
                  ),
                  border: `1px solid ${alpha(
                    theme.palette[upgradeActionStatus.type === 'success' ? 'success' : 'error'].main, 
                    0.1
                  )}`,
                  borderRadius: 2,
                  maxWidth: 400
                }}
              >
                <Stack direction="row" spacing={1} alignItems="center">
                  {upgradeActionStatus.type === 'success' ? (
                    <CheckCircleIcon sx={{ color: theme.palette.success.main }} fontSize="small" />
                  ) : (
                    <ErrorIcon sx={{ color: theme.palette.error.main }} fontSize="small" />
                  )}
                  <Typography variant="body2" color="text.secondary">
                    {upgradeActionStatus.message}
                  </Typography>
                </Stack>
              </Paper>
            )}
          </Stack>
        </Paper>
      );
    }

    return (
      <Box sx={{ width: '100%' }}>
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="flight segments tabs"
        >
          {segments.map((segment, index) => (
            <Tab 
              key={index}
              label={formatRoute(segment)}
              id={`segment-tab-${index}`}
              aria-controls={`segment-panel-${index}`}
            />
          ))}
        </Tabs>

        {segments.map((segment, index) => (
          <Box
            key={index}
            role="tabpanel"
            hidden={selectedTab !== index}
            id={`segment-panel-${index}`}
            aria-labelledby={`segment-tab-${index}`}
            sx={{ mt: 2 }}
          >
            {selectedTab === index && (
              <Stack spacing={3}>
                {/* Upgrade Offers Graph */}
                <Card>
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      Upgrade Offer History
                    </Typography>
                    <Box sx={{ height: 400, mt: 2 }}>
                      <LineChart
                        dataset={prepareUpgradeOfferData(segment.upgrade_offer_history)}
                        xAxis={[{
                          dataKey: 'date',
                          scaleType: 'time',
                          valueFormatter: (value) => {
                            // Ensure we have a valid date before formatting
                            if (!(value instanceof Date) || isNaN(value.getTime())) {
                              return 'Invalid Date';
                            }
                            return dayjs(value).format('MMM D, YYYY HH:mm z');
                          },
                          label: 'Date and Time',
                        }]}
                        yAxis={[
                          {
                            dataKey: 'price',
                            scaleType: 'linear',
                            valueFormatter: (value) => (value ? `$${value}` : 'N/A'),
                            label: 'Upgrade Price (USD)',
                          },
                          {
                            dataKey: 'miles',
                            scaleType: 'linear',
                            valueFormatter: (value) => (value ? `${value.toLocaleString()} miles` : 'N/A'),
                            label: 'Miles Required',
                            position: 'right',
                          }
                        ]}
                        series={[
                          ...segment.upgradeable_cabins.map((cabin, i) => ({
                            dataKey: `${cabin}_price`,
                            label: `${cabin} (Cash)`,
                            color: `hsl(${(i * 137.508) % 360}, 70%, 45%)`,
                            valueFormatter: (value) => (value ? `$${value}` : 'N/A'),
                          })),
                          ...segment.upgradeable_cabins.map((cabin, i) => ({
                            dataKey: `${cabin}_miles`,
                            label: `${cabin} (Miles)`,
                            color: `hsl(${(i * 137.508) % 360}, 70%, 45%)`,
                            style: { strokeDasharray: '5 5' },
                            valueFormatter: (value) => (value ? `${value.toLocaleString()} miles` : 'N/A'),
                          }))
                        ]}
                        height={350}
                        margin={{ 
                          left: 60,
                          right: 60,
                          top: 20,
                          bottom: 40
                        }}
                      />
                    </Box>
                  </CardContent>
                </Card>

                {/* Active Alerts */}
                <Card>
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      Active Upgrade Alerts ({segment.upgrade_alert_info.length})
                    </Typography>
                    <TableContainer>
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell>Cabin</TableCell>
                            <TableCell>Target Price</TableCell>
                            <TableCell>Type</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Created</TableCell>
                            <TableCell>Last Updated</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {segment.upgrade_alert_info.map((alert, alertIndex) => (
                            <TableRow key={alertIndex}>
                              <TableCell>{alert.cabin_offered}</TableCell>
                              <TableCell>
                                {alert.currency === 'cash' 
                                  ? `$${alert.price.toFixed(2)}` 
                                  : `${alert.price.toLocaleString()} miles`}
                              </TableCell>
                              <TableCell>{alert.currency === 'cash' ? 'Cash' : 'Miles'}</TableCell>
                              <TableCell>
                                <Chip
                                  label={alert.is_active ? "Active" : "Inactive"}
                                  color={alert.is_active ? "success" : "default"}
                                  size="small"
                                />
                              </TableCell>
                              <TableCell>{formatDateTime(alert.created_at)}</TableCell>
                              <TableCell>{formatDateTime(alert.updated_at)}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </CardContent>
                </Card>
              </Stack>
            )}
          </Box>
        ))}
      </Box>
    );
  };

  const renderTrackingDialog = () => {
    const groups = groupChips(reservationData);
    
    const getWarningMessage = (key, isChecked) => {
      switch(key) {
        case 'is_award':
          return `Changing reservation ${isChecked ? 'to' : 'from'} Award is not advised and will impact how this reservation is treated. All prices will be pulled as award moving forward`;
        case 'is_basic_economy':
          return "Adding or removing the basic economy flag will let the user toggle on Tracking. It is advised that if you intend to track this reservation that you also make sure that \"Tracked\" is on and \"Active\" and \"Auto Rebook\"";
        case 'is_elite_upgrade':
          return "When an elite upgrade is on a reservation, it disables, by default, the user from tracking the reservation.";
        case 'is_refundable':
          return "This will begin to pull the refundable price for this ticket.";
        case 'is_direct_booking':
          return "This flag should be on when a booking is direct and off when a booking isn't direct. In rare cases are we able to modify bookings made with a third party.";
        default:
          return null;
      }
    };

    const handleStatusChange = (key, newValue) => {
      const warningMessage = getWarningMessage(key, newValue);
      if (warningMessage) {
        const confirmed = window.confirm(warningMessage);
        if (confirmed) {
          setTrackingStatuses(prev => ({
            ...prev,
            [key]: newValue
          }));
        }
      }
    };

    return (
      <Dialog
        open={openTrackingDialog}
        onClose={() => setOpenTrackingDialog(false)}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          elevation: 0,
          sx: {
            borderRadius: '16px',
            maxWidth: '500px'
          }
        }}
      >
        <DialogTitle sx={{ 
          pb: 1,
          pt: 2.5,
          px: 3,
        }}>
          <Typography variant="h6" sx={{ 
            fontWeight: 600,
            textAlign: 'center',
            color: theme.palette.text.primary,
            fontSize: '1.125rem'
          }}>
            Edit Reservation Status
          </Typography>
        </DialogTitle>

        <DialogContent sx={{ px: 3 }}>
          <Stack spacing={2} sx={{ mt: 1 }}>
            {/* Tracking Status Accordion */}
            <Accordion
              defaultExpanded
              elevation={0}
              sx={{
                border: `1px solid ${theme.palette.divider}`,
                borderRadius: '12px !important',
                '&:before': { display: 'none' },
                '& .MuiAccordionSummary-root': {
                  borderRadius: '12px',
                  '&:hover': { bgcolor: 'rgba(25, 118, 210, 0.04)' }
                }
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{ 
                  px: 2,
                  '& .MuiAccordionSummary-content': {
                    my: 1
                  }
                }}
              >
                <Typography 
                  variant="subtitle2" 
                  sx={{ 
                    fontWeight: 600,
                    color: theme.palette.primary.main,
                    fontSize: '0.875rem'
                  }}
                >
                  Tracking Status
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ px: 2, pt: 0, pb: 2 }}>
                <Stack spacing={1}>
                  {Object.entries(trackingStatuses)
                    .filter(([key]) => groups.tracking.some(item => 
                      `is_${item.label.toLowerCase().replace(' ', '_')}` === key ||
                      key === 'is_auto_rebook_enabled'
                    ))
                    .map(([key, value]) => (
                      <Box
                        key={key}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          p: 1.5,
                          borderRadius: '12px',
                          bgcolor: value ? 'primary.50' : 'background.default',
                          border: `1px solid ${value ? theme.palette.primary.main : theme.palette.divider}`,
                          transition: 'all 0.2s ease-in-out'
                        }}
                      >
                        <Typography sx={{ 
                          fontSize: '0.875rem',
                          color: value ? 'primary.main' : 'text.secondary',
                          fontWeight: value ? 500 : 400
                        }}>
                          {key.split('_').map(word => 
                            word.charAt(0).toUpperCase() + word.slice(1)
                          ).join(' ').replace('Is ', '')}
                        </Typography>
                        <Switch
                          checked={value}
                          onChange={(e) => handleStatusChange(key, e.target.checked)}
                          color="primary"
                          size="small"
                        />
                      </Box>
                    ))}
                </Stack>
              </AccordionDetails>
            </Accordion>

            {/* Reservation Type Accordion */}
            <Accordion
              defaultExpanded
              elevation={0}
              sx={{
                border: `1px solid ${theme.palette.divider}`,
                borderRadius: '12px !important',
                '&:before': { display: 'none' },
                '& .MuiAccordionSummary-root': {
                  borderRadius: '12px',
                  '&:hover': { bgcolor: 'rgba(211, 47, 47, 0.04)' }
                }
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{ 
                  px: 2,
                  '& .MuiAccordionSummary-content': {
                    my: 1
                  }
                }}
              >
                <Typography 
                  variant="subtitle2" 
                  sx={{ 
                    fontWeight: 600,
                    color: theme.palette.error.main,
                    fontSize: '0.875rem'
                  }}
                >
                  Reservation Type
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ px: 2, pt: 0, pb: 2 }}>
                <Stack spacing={1}>
                  {[
                    { key: 'is_award', label: 'Award' },
                    { key: 'is_basic_economy', label: 'Basic Economy' },
                    { key: 'is_elite_upgrade', label: 'Elite Upgrade' },
                    { key: 'is_refundable', label: 'Refundable' },
                    { key: 'is_direct_booking', label: 'Direct Booking' }
                  ].map(({ key, label }) => (
                    <Box
                      key={key}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        p: 1.5,
                        borderRadius: '12px',
                        bgcolor: trackingStatuses[key] ? 'error.50' : 'background.default',
                        border: `1px solid ${trackingStatuses[key] ? theme.palette.error.main : theme.palette.divider}`,
                        transition: 'all 0.2s ease-in-out'
                      }}
                    >
                      <Typography sx={{ 
                        fontSize: '0.875rem',
                        color: trackingStatuses[key] ? 'error.main' : 'text.secondary',
                        fontWeight: trackingStatuses[key] ? 500 : 400
                      }}>
                        {label}
                      </Typography>
                      <Switch
                        checked={trackingStatuses[key] || false}
                        onChange={(e) => handleStatusChange(key, e.target.checked)}
                        color="error"
                        size="small"
                      />
                    </Box>
                  ))}
                </Stack>
              </AccordionDetails>
            </Accordion>
          </Stack>
        </DialogContent>

        <DialogActions sx={{ px: 3, pb: 3, pt: 2 }}>
          <Button 
            onClick={() => setOpenTrackingDialog(false)}
            sx={{ 
              color: theme.palette.text.secondary,
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.04)'
              }
            }}
          >
            Cancel
          </Button>
          <Button 
            onClick={handleUpdateTrackingStatus}
            disabled={isUpdatingStatus}
            variant="contained"
            sx={{ 
              px: 3,
              borderRadius: '8px',
              boxShadow: 'none',
              '&:hover': {
                boxShadow: 'none'
              }
            }}
          >
            {isUpdatingStatus ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              'Save Changes'
            )}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  if (!reservationData) {
    return <Typography>No reservation data available</Typography>;
  }

  return (
    <ThemeProvider theme={theme}>
      <Paper 
        elevation={3} 
        sx={{ 
          p: 2, 
          mb: 2, 
          border: '2px solid #535aff',
          transition: 'box-shadow 0.3s',
          boxShadow: isHovered ? '0 0 10px 0 #535aff' : 'none',
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <Box 
          sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center',
            mb: 4,
            position: 'relative',
            gap: 2
          }}
        >
          {/* Header Section */}
          <Box sx={{ 
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            mb: 4
          }}>
            {/* Left side - Info Module */}
            <Paper
              elevation={0}
              sx={{ 
                display: 'flex',
                alignItems: 'center',
                gap: 3,
                py: 2,
                px: 3,
                borderRadius: '8px',
                border: `1px solid ${theme.palette.divider}`,
                backgroundColor: theme.palette.background.paper,
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Typography color="text.secondary">Reservation ID:</Typography>
                <Typography 
                  sx={{ 
                    fontSize: '1.5rem',
                    fontWeight: 700,
                    color: '#535aff',
                    backgroundColor: `${alpha('#535aff', 0.15)}`,
                    padding: '2px 8px',
                    borderRadius: '4px'
                  }}
                >
                  {reservationData.reservation_id}
                </Typography>
                <Tooltip title={copiedField === 'reservation_id' ? "Copied!" : "Copy ID"}>
                  <IconButton 
                    size="small" 
                    onClick={() => handleCopy(reservationData.reservation_id.toString(), 'reservation_id')}
                    sx={{ 
                      padding: '2px',
                      '&:hover': { 
                        backgroundColor: `${alpha('#535aff', 0.15)}`
                      }
                    }}
                  >
                    <ContentCopyIcon sx={{ fontSize: 16 }} />
                  </IconButton>
                </Tooltip>
              </Box>

              <Divider orientation="vertical" flexItem />

              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Typography color="text.secondary">PNR:</Typography>
                <Typography 
                  sx={{ 
                    fontSize: '1.5rem',
                    fontWeight: 700,
                    color: theme.palette.primary.main,
                    backgroundColor: `${theme.palette.primary.main}15`,
                    padding: '2px 8px',
                    borderRadius: '4px'
                  }}
                >
                  {reservationData.pnr}
                </Typography>
                <Tooltip title={copiedField === 'pnr' ? "Copied!" : "Copy PNR"}>
                  <IconButton 
                    size="small" 
                    onClick={() => handleCopy(reservationData.pnr, 'pnr')}
                    sx={{ 
                      padding: '2px',
                      '&:hover': { 
                        backgroundColor: `${theme.palette.primary.main}15`
                      }
                    }}
                  >
                    <ContentCopyIcon sx={{ fontSize: 16 }} />
                  </IconButton>
                </Tooltip>
              </Box>

              <Divider orientation="vertical" flexItem />

              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Typography color="text.secondary">User ID:</Typography>
                <Typography fontWeight="500">{reservationData.user_id}</Typography>
                <IconButton 
                  size="small" 
                  onClick={() => navigate(`/users?user_id=${reservationData.user_id}`)}
                  sx={{ 
                    color: '#535aff',
                    padding: '2px',
                    '&:hover': { 
                      backgroundColor: `${alpha('#535aff', 0.15)}`
                    }
                  }}
                >
                  <ArrowForwardIcon sx={{ fontSize: 16 }} />
                </IconButton>
              </Box>
            </Paper>

            {/* Right side - Action Buttons */}
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<EditIcon />}
                onClick={() => navigate(`/record-refaring?reservation_id=${reservationData.reservation_id}`)}
                sx={{ 
                  borderRadius: '8px',
                  textTransform: 'none',
                  boxShadow: 'none',
                  bgcolor: '#535aff',
                  '&:hover': { 
                    bgcolor: alpha('#535aff', 0.8),
                    boxShadow: '0 2px 8px rgba(83, 90, 255, 0.25)'
                  }
                }}
              >
                Record Refaring
              </Button>
              <Button
                variant="outlined"
                startIcon={<RefreshIcon />}
                onClick={() => setOpenConfirmDialog(true)}
                disabled={isRefreshingReservation}
                sx={{ 
                  borderRadius: '8px',
                  textTransform: 'none',
                  borderColor: '#535aff',
                  color: '#535aff',
                  '&:hover': {
                    backgroundColor: `${alpha('#535aff', 0.1)}`,
                    borderColor: '#535aff'
                  }
                }}
              >
                {isRefreshingReservation ? <CircularProgress size={20} /> : 'Refresh'}
              </Button>
            </Box>
          </Box>

        </Box>
        {renderStatusChips()}
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Paper elevation={0} sx={{
              p: 2.5,
              borderRadius: '12px',
              backgroundColor: alpha('#535aff', 0.04),
              border: `1px solid ${alpha('#535aff', 0.12)}`,
              height: '100%'
            }}>
              <Stack spacing={2}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  {getAirlineLogo(reservationData.airline) ? (
                    <Box
                      component="img"
                      src={getAirlineLogo(reservationData.airline)}
                      alt={`${reservationData.airline} logo`}
                      sx={{
                        width: 24,
                        height: 24,
                        objectFit: 'contain',
                        border: `1px solid ${alpha(theme.palette.divider, 0.5)}`,
                        borderRadius: '4px',
                      }}
                    />
                  ) : (
                    <FlightIcon sx={{ color: '#535aff' }} />
                  )}
                  <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                    {reservationData.airline?.toUpperCase()}
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <PaidIcon sx={{ color: theme.palette.success.main }} />
                  <Typography>
                    <Box component="span" sx={{ color: 'text.secondary' }}>Total Payment:</Box>
                    <Box component="span" sx={{ ml: 1, fontWeight: 600 }}>
                      {formatCurrency(reservationData.payment_amount)}
                    </Box>
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <ConfirmationNumberIcon sx={{ color: theme.palette.secondary.main }} />
                  <Typography>
                    <Box component="span" sx={{ color: 'text.secondary' }}>Ticket Number:</Box>
                    <Box component="span" sx={{ ml: 1, fontFamily: 'monospace' }}>{reservationData.ticket_number}</Box>
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <EventIcon sx={{ color: theme.palette.error.main }} />
                  <Typography>
                    <Box component="span" sx={{ color: 'text.secondary' }}>Issue Date:</Box>
                    <Box component="span" sx={{ ml: 1 }}>{formatDateTime(reservationData.ticket_issue_date)}</Box>
                  </Typography>
                </Box>
              </Stack>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper elevation={0} sx={{
              p: 2.5,
              borderRadius: '12px',
              backgroundColor: alpha('#535aff', 0.04),
              border: `1px solid ${alpha('#535aff', 0.12)}`,
              height: '100%'
            }}>
              <Stack spacing={2}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <AccessTimeIcon sx={{ color: theme.palette.info.main }} />
                  <Typography>
                    <Box component="span" sx={{ color: 'text.secondary' }}>Date Added:</Box>
                    <Box component="span" sx={{ ml: 1 }}>{formatDateTime(reservationData.created_at)}</Box>
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <UpdateIcon sx={{ color: theme.palette.success.main }} />
                  <Typography>
                    <Box component="span" sx={{ color: 'text.secondary' }}>Last Refreshed:</Box>
                    <Box component="span" sx={{ ml: 1 }}>{formatDateTime(reservationData.last_refreshed_datetime)}</Box>
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <ImportExportIcon sx={{ color: theme.palette.warning.main }} />
                  <Typography>
                    <Box component="span" sx={{ color: 'text.secondary' }}>Import Method:</Box>
                    <Box component="span" sx={{ ml: 1 }}>{formatImportMethod(reservationData.import_method)}</Box>
                  </Typography>
                </Box>
              </Stack>
            </Paper>
          </Grid>
        </Grid>

        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">Flight Information</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {reservationData.trips && reservationData.trips.map((trip, index) => renderFlightCard(trip, index))}
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">
              Passenger Information ({(reservationData.passengers || reservationData.trips?.[0]?.passengers || []).length} {
                (reservationData.passengers || reservationData.trips?.[0]?.passengers || []).length === 1 ? 'Passenger' : 'Passengers'
              })
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={3}>
              {(reservationData.passengers || reservationData.trips?.[0]?.passengers || []).map((passenger, index) => (
                <Grid item xs={12} md={6} key={passenger.passenger_id}>
                  <Paper
                    elevation={0}
                    sx={{ 
                      p: 3,
                      height: '100%',
                      border: `1px solid ${theme.palette.divider}`,
                      borderRadius: 2,
                      position: 'relative',
                      overflow: 'hidden',
                      transition: 'all 0.3s ease-in-out',
                      ...(passenger.loyalty_info && {
                        '&:before': {
                          content: '""',
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          right: 0,
                          height: '3px',
                          background: getLoyaltyStyle(passenger.loyalty_info).gradient,
                          backgroundSize: '200% 100%',
                          opacity: 0.7,
                          transition: 'opacity 0.3s ease-in-out'
                        },
                        '&:hover': {
                          '&:before': {
                            opacity: 1,
                            animation: 'shimmerStripe 2s ease-in-out'
                          }
                        },
                        ...(passenger.loyalty_info.status === 'INVITE_ONLY' && {
                          background: 'linear-gradient(135deg, #1a1a1a 0%, #0a0a0a 100%)',
                          border: '1px solid #2C3E50',
                          '& .MuiTypography-root': {
                            color: '#FFF'
                          },
                          '& .MuiSvgIcon-root': {
                            color: '#FFF'
                          },
                          '& .MuiChip-root': {
                            border: '1px solid #2C3E50'
                          }
                        })
                      })
                    }}
                  >
                    {/* Header with Name and Status */}
                    <Box sx={{ 
                      display: 'flex', 
                      alignItems: 'center', 
                      gap: 2,
                      pb: 2,
                      borderBottom: `1px solid ${theme.palette.divider}`
                    }}>
                      <Avatar
                        sx={{ 
                          bgcolor: alpha(theme.palette.primary.main, 0.1),
                          color: theme.palette.primary.main,
                          width: 48,
                          height: 48,
                          fontSize: '1.2rem',
                          fontWeight: 600
                        }}
                      >
                        {`${passenger.first_name?.[0]}${passenger.last_name?.[0]}`}
                      </Avatar>
                      <Box sx={{ flex: 1 }}>
                        <Typography variant="h6" sx={{ fontWeight: 600 }}>
                          {passenger.first_name} {passenger.last_name}
                        </Typography>
                        <Box sx={{ display: 'flex', gap: 1, mt: 0.5 }}>
                          {passenger.loyalty_info && passenger.loyalty_info.status && (
                            <Chip
                              icon={<StarIcon sx={{ 
                                fontSize: '16px !important',
                                color: 'inherit'
                              }} />}
                              label={(passenger.loyalty_info.status || '').replace('_', ' ')}
                              size="small"
                              sx={{ 
                                fontWeight: 600,
                                position: 'relative',
                                overflow: 'hidden',
                                background: getLoyaltyStyle(passenger.loyalty_info).gradient,
                                backgroundSize: getLoyaltyStyle(passenger.loyalty_info).shimmer ? '200% 100%' : '100% 100%',
                                color: getLoyaltyStyle(passenger.loyalty_info).status === 'PLATINUM' ? 
                                  LOYALTY_TIER_STYLES.PLATINUM.textColor : '#FFF',
                                textShadow: '0 1px 1px rgba(0,0,0,0.2)',
                                border: 'none',
                                transition: 'all 0.3s ease-in-out',
                                '& .MuiChip-icon': { 
                                  color: 'inherit'
                                },
                                ...(getLoyaltyStyle(passenger.loyalty_info).shimmer && {
                                  '&:hover': {
                                    animation: 'shimmerStripe 2s ease-in-out',
                                    cursor: 'default'
                                  },
                                  '@keyframes shimmerStripe': {
                                    '0%': {
                                      backgroundPosition: '200% 0'
                                    },
                                    '100%': {
                                      backgroundPosition: '-200% 0'
                                    }
                                  }
                                })
                              }}
                            />
                          )}
                          <Chip
                            label={`Passenger ${index + 1}`}
                            size="small"
                            sx={{ 
                              bgcolor: alpha(theme.palette.info.main, 0.1),
                              color: theme.palette.info.main,
                              fontWeight: 600
                            }}
                          />
                        </Box>
                      </Box>
                    </Box>

                    <Stack spacing={2} sx={{ mt: 2 }}>
                      {/* Contact & Loyalty Section */}
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                            <EmailIcon sx={{ color: theme.palette.text.secondary }} />
                            <Box sx={{ flex: 1 }}>
                              <Typography variant="body2" color="text.secondary">Email</Typography>
                              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <Typography variant="body1">{passenger.email}</Typography>
                                <IconButton 
                                  size="small" 
                                  onClick={() => handleCopy(passenger.email)}
                                  sx={{ p: 0.5 }}
                                >
                                  <ContentCopyIcon fontSize="small" />
                                </IconButton>
                              </Box>
                            </Box>
                          </Box>
                        </Grid>
                        
                        <Grid item xs={12}>
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                            <PhoneIcon sx={{ color: theme.palette.text.secondary }} />
                            <Box sx={{ flex: 1 }}>
                              <Typography variant="body2" color="text.secondary">Phone</Typography>
                              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <Typography variant="body1">{passenger.phone}</Typography>
                                <IconButton 
                                  size="small" 
                                  onClick={() => handleCopy(passenger.phone)}
                                  sx={{ p: 0.5 }}
                                >
                                  <ContentCopyIcon fontSize="small" />
                                </IconButton>
                              </Box>
                            </Box>
                          </Box>
                        </Grid>

                        {passenger.loyalty_info && (
                          <Grid item xs={12}>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                              <CardMembershipIcon sx={{ color: theme.palette.text.secondary }} />
                              <Box sx={{ flex: 1 }}>
                                <Typography variant="body2" color="text.secondary">
                                  Loyalty Number
                                </Typography>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                  <Typography variant="body1">
                                    {passenger.loyalty_info.loyalty_number}
                                  </Typography>
                                  <IconButton 
                                    size="small" 
                                    onClick={() => handleCopy(passenger.loyalty_info.loyalty_number)}
                                    sx={{ p: 0.5 }}
                                  >
                                    <ContentCopyIcon fontSize="small" />
                                  </IconButton>
                                </Box>
                              </Box>
                            </Box>
                          </Grid>
                        )}
                      </Grid>

                      {/* Travel Documents Section */}
                      <Stack spacing={1.5}>
                        <Typography variant="subtitle2" color="text.secondary" fontWeight={600}>
                          Travel Documents
                        </Typography>
                        <Box sx={{ 
                          display: 'flex', 
                          flexWrap: 'wrap',
                          gap: 1
                        }}>
                          {[
                            { label: 'KTN', value: passenger.has_ktn, icon: SecurityIcon },
                            { label: 'Clear', value: passenger.has_clear, icon: VerifiedUserIcon },
                            { label: 'Canadian Travel', value: passenger.has_canadian_travel_number, icon: FlightTakeoffIcon },
                            { label: 'Redress', value: passenger.has_redress_number, icon: GppGoodIcon }
                          ].map((doc) => (
                            <Chip
                              key={doc.label}
                              icon={<doc.icon sx={{ fontSize: '16px !important' }} />}
                              label={doc.label}
                              variant={doc.value ? "filled" : "outlined"}
                              size="small"
                              sx={{ 
                                bgcolor: doc.value ? alpha(theme.palette.success.main, 0.1) : 'transparent',
                                color: doc.value ? theme.palette.success.main : theme.palette.text.secondary,
                                borderColor: doc.value ? 'transparent' : theme.palette.divider,
                                '& .MuiChip-icon': { 
                                  color: doc.value ? theme.palette.success.main : theme.palette.text.secondary
                                }
                              }}
                            />
                          ))}
                        </Box>
                      </Stack>
                    </Stack>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
              <Typography variant="h6">Pricing Information</Typography>
              <a 
                href={reservationData?.search_link} 
                target="_blank" 
                rel="noopener noreferrer"
                style={{ textDecoration: 'none' }} // Optional: to remove underline
              >
                <Button
                  variant="outlined"
                  size="small"
                  startIcon={getAirlineLogo(reservationData.airline) ? (
                    <Box
                      component="img"
                      src={getAirlineLogo(reservationData.airline)}
                      alt={`${reservationData.airline} logo`}
                      sx={{
                        width: 20,
                        height: 20,
                        objectFit: 'contain',
                        border: `1px solid ${alpha(theme.palette.divider, 0.5)}`,
                        borderRadius: '4px',
                      }}
                    />
                  ) : (
                    <Box component="span" sx={{ transform: 'rotate(-45deg)' }}>→</Box>
                  )}
                  endIcon={<Box component="span" sx={{ transform: 'rotate(-45deg)' }}>→</Box>}
                  sx={{ 
                    mr: 4, // Add margin to prevent overlap with expandIcon
                    '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' }
                  }}
                >
                  Price Directly with {reservationData?.airline?.charAt(0).toUpperCase() + reservationData?.airline?.slice(1).toLowerCase()}
                </Button>
              </a>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6} md={3}>
                <Typography variant="h4" fontWeight="bold">
                  {reservationData.is_award ? formatAwardPrice() : formatCurrency(reservationData.payment_amount)}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Total Original {reservationData.is_award ? 'Miles' : 'Payment Amount'}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Ticket Issue Date: {formatDate(reservationData.ticket_issue_date)}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Typography variant="h4" fontWeight="bold">
                  {formatCurrency(reservationData.total_saved_amount, reservationData.is_award)}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Total Saved on this Reservation
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Typography 
                  variant="h4" 
                  fontWeight="bold"
                  sx={isPriceLower ? greenTextStyle : {}}
                >
                  {formatCurrency(reservationData.current_effective_price, reservationData.is_award)}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Current Effective {reservationData.is_award ? 'Miles' : 'Price'}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {reservationData.is_award ? 'Miles' : 'Fare'} Paid, Adjusted for Lowest {reservationData.is_award ? 'Miles' : 'Fare'} Guarantee
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box sx={{ flexGrow: 1, opacity: isRefreshing ? 0.5 : 1 }}>
                    <Typography 
                      variant="h4" 
                      fontWeight="bold"
                      sx={isPriceLower ? greenTextStyle : {}}
                    >
                      {formatCurrency(lastRefreshedPrice, reservationData.is_award)}
                    </Typography>
                  </Box>
                  <Tooltip title={!reservationData.is_upcoming ? "You can't refresh a price of a previous reservation" : ""}>
                    <span>
                      <IconButton 
                        onClick={refreshPrice} 
                        disabled={isRefreshing || !reservationData.is_upcoming}
                        sx={{ 
                          ml: 1,
                          '&.Mui-disabled': {
                            color: theme.palette.action.disabled
                          }
                        }}
                      >
                        {isRefreshing ? <CircularProgress size={24} /> : <RefreshIcon />}
                      </IconButton>
                    </span>
                  </Tooltip>
                </Box>
                <Typography variant="body2" color="textSecondary">
                  Last {reservationData.is_award ? 'Miles' : 'Price'} Seen
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {justRefreshed 
                    ? `${reservationData.is_award ? 'Miles' : 'Price'} Found Just Now (${formatToEST(lastRefreshedDateTime)})`
                    : formatToEST(lastRefreshedDateTime)}
                </Typography>
                {isPriceLower && (
                  <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                    <ArrowDownwardIcon sx={{ color: theme.palette.success.main, mr: 0.5 }} />
                    <Typography variant="body2" sx={greenTextStyle}>
                      {formatCurrency(Math.abs(priceDifference), reservationData.is_award)} lower
                    </Typography>
                  </Box>
                )}
              </Grid>
            </Grid>
            <Box mt={4}>
              <Typography variant="h6" gutterBottom>Historical Prices</Typography>
              {renderHistoricalPriceChart()}
              <Box mt={2}>
                <Typography variant="h6" gutterBottom>
                  Price History Table (Price Refreshed {reservationData.price_history.length} Times)
                </Typography>
                {renderHistoricalPriceTable()}
              </Box>
            </Box>

            {/* Savings and Credits Section */}
            <Box mt={4}>
              <Box sx={{ 
                display: 'flex', 
                justifyContent: 'space-between', 
                alignItems: 'center',
                mb: 3 
              }}>
                <Typography variant="h6" sx={{ 
                  fontWeight: 600,
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1
                }}>
                  <PaidIcon sx={{ color: theme.palette.success.main }} />
                  Savings and Credits
                </Typography>
                {reservationData.savings_info?.total_savings > 0 && (
                  <Paper
                    elevation={0}
                    sx={{
                      px: 3,
                      py: 1,
                      bgcolor: alpha(theme.palette.success.main, 0.1),
                      border: `1px solid ${alpha(theme.palette.success.main, 0.2)}`,
                      borderRadius: 2,
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1
                    }}
                  >
                    <Typography variant="body2" color="success.main" fontWeight={500}>
                      Total Savings:
                    </Typography>
                    <Typography variant="h6" color="success.main" fontWeight={600}>
                      {formatCurrency(reservationData.savings_info.total_savings)}
                    </Typography>
                  </Paper>
                )}
              </Box>
              
              {(!reservationData.savings_info || 
                (!reservationData.savings_info.price_change_events?.length && 
                 !reservationData.savings_info.voucher_credits?.length)) ? (
                  <Paper 
                    elevation={0} 
                    sx={{ 
                      p: 4,
                      textAlign: 'center',
                      border: `1px dashed ${theme.palette.divider}`,
                      borderRadius: 2,
                      bgcolor: alpha(theme.palette.primary.main, 0.02)
                    }}
                  >
                    <Stack spacing={2} alignItems="center">
                      <Box sx={{ 
                        width: 48, 
                        height: 48, 
                        borderRadius: '50%',
                        bgcolor: alpha(theme.palette.primary.main, 0.1),
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}>
                        <PaidIcon color="primary" />
                      </Box>
                      <Typography variant="subtitle1" color="text.secondary">
                        No savings or credits available yet
                      </Typography>
                      <Typography variant="body2" color="text.secondary" sx={{ maxWidth: 400 }}>
                        When the reservation has savings or credits, they will appear here
                      </Typography>
                    </Stack>
                  </Paper>
                ) : (
                  <Stack spacing={2}>
                    {reservationData.savings_info.voucher_credits.map((credit) => (
                      <Paper
                        key={credit.credit_id}
                        elevation={0}
                        sx={{ 
                          border: `1px solid ${theme.palette.divider}`,
                          borderRadius: 2,
                          overflow: 'hidden',
                          transition: 'all 0.2s ease-in-out',
                          '&:hover': {
                            borderColor: theme.palette.primary.main,
                            boxShadow: `0 0 0 1px ${theme.palette.primary.main}`,
                          }
                        }}
                      >
                        <Box sx={{ 
                          p: 2,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          gap: 2,
                          cursor: 'pointer',
                          '&:hover': { bgcolor: alpha(theme.palette.primary.main, 0.02) }
                        }}
                        onClick={(e) => {
                          const details = e.currentTarget.nextElementSibling;
                          details.style.display = details.style.display === 'none' ? 'block' : 'none';
                        }}
                        >
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                            <Box sx={{ 
                              width: 40,
                              height: 40,
                              borderRadius: '50%',
                              bgcolor: alpha(theme.palette.primary.main, 0.1),
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center'
                            }}>
                              <ConfirmationNumberIcon sx={{ color: theme.palette.primary.main }} />
                            </Box>
                            <Stack spacing={0.5}>
                              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <Chip
                                  label={credit.credit_type.toUpperCase()}
                                  size="small"
                                  sx={{ 
                                    bgcolor: alpha('#535aff', 0.1),
                                    color: '#535aff',
                                    fontWeight: 600,
                                    fontSize: '0.75rem'
                                  }}
                                />
                                {credit.is_system_generated && (
                                  <Chip
                                    label="AUTO"
                                    size="small"
                                    sx={{ 
                                      bgcolor: alpha(theme.palette.success.main, 0.1),
                                      color: theme.palette.success.main,
                                      fontWeight: 600,
                                      fontSize: '0.75rem'
                                    }}
                                  />
                                )}
                              </Box>
                              <Typography variant="body2" color="text.secondary">
                                {credit.issuing_airline.toUpperCase()} • Expires {formatDate(credit.expiration_date)}
                              </Typography>
                            </Stack>
                          </Box>
                          
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 3 }}>
                            <Typography variant="h6" sx={{ fontWeight: 600 }}>
                              {formatCurrency(credit.amount)}
                            </Typography>
                            <Chip
                              label={credit.is_used ? 'Used' : 'Available'}
                              size="small"
                              sx={{ 
                                minWidth: 80,
                                bgcolor: credit.is_used 
                                  ? alpha(theme.palette.text.disabled, 0.1)
                                  : alpha(theme.palette.success.main, 0.1),
                                color: credit.is_used
                                  ? theme.palette.text.disabled
                                  : theme.palette.success.main,
                                fontWeight: 600,
                                fontSize: '0.75rem'
                              }}
                            />
                            <Box sx={{ display: 'flex', gap: 1 }}>
                              {credit.receipt_url && (
                                <Tooltip title="View Receipt">
                                  <IconButton
                                    size="small"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      window.open(credit.receipt_url, '_blank');
                                    }}
                                    sx={{ 
                                      color: '#535aff',
                                      '&:hover': { bgcolor: alpha('#535aff', 0.1) }
                                    }}
                                  >
                                    <ReceiptIcon fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                              )}
                              <IconButton
                                size="small"
                                sx={{ 
                                  transition: 'transform 0.2s',
                                  '&.expanded': { transform: 'rotate(180deg)' },
                                  color: theme.palette.text.secondary
                                }}
                              >
                                <ExpandMoreIcon fontSize="small" />
                              </IconButton>
                            </Box>
                          </Box>
                        </Box>
                        
                        <Box 
                          sx={{ 
                            display: 'none',
                            borderTop: `1px solid ${theme.palette.divider}`,
                            bgcolor: alpha(theme.palette.primary.main, 0.02),
                            p: 2
                          }}
                        >
                          <Grid container spacing={3}>
                            <Grid item xs={12} md={6}>
                              <Stack spacing={2}>
                                <Typography variant="subtitle2" color="text.secondary" fontWeight={600}>
                                  Credit Details
                                </Typography>
                                <Box sx={{ 
                                  display: 'grid', 
                                  gridTemplateColumns: 'auto 1fr',
                                  gap: '8px 16px'
                                }}>
                                  <Typography variant="body2" color="text.secondary">Reference:</Typography>
                                  <Typography variant="body2" fontWeight={500}>{credit.reference_number}</Typography>
                                  
                                  <Typography variant="body2" color="text.secondary">Issue Date:</Typography>
                                  <Typography variant="body2" fontWeight={500}>{formatDate(credit.issue_date)}</Typography>
                                  
                                  <Typography variant="body2" color="text.secondary">Expiration:</Typography>
                                  <Typography variant="body2" fontWeight={500}>{formatDate(credit.expiration_date)}</Typography>
                                </Box>
                              </Stack>
                            </Grid>
                            
                            {credit.notes && (
                              <Grid item xs={12} md={6}>
                                <Stack spacing={2}>
                                  <Typography variant="subtitle2" color="text.secondary" fontWeight={600}>
                                    Notes
                                  </Typography>
                                  <Typography variant="body2">
                                    {credit.notes}
                                  </Typography>
                                </Stack>
                              </Grid>
                            )}
                            
                            {/* Price Change Event Details */}
                            {reservationData.savings_info.price_change_events
                              .filter(event => 
                                event.voucher_credits?.some(vc => vc.credit_id === credit.credit_id)
                              )
                              .map(event => (
                                <Grid item xs={12} key={event.event_id}>
                                  <Stack spacing={2}>
                                    <Typography variant="subtitle2" color="text.secondary" fontWeight={600}>
                                      Price Change Details
                                    </Typography>
                                    <Box sx={{ 
                                      display: 'flex',
                                      gap: 4,
                                      p: 2,
                                      bgcolor: '#fff',
                                      borderRadius: 1,
                                      border: `1px solid ${theme.palette.divider}`
                                    }}>
                                      <Box>
                                        <Typography variant="caption" color="text.secondary">
                                          Original Price
                                        </Typography>
                                        <Typography variant="subtitle2" fontWeight={600}>
                                          {formatCurrency(event.original_price)}
                                        </Typography>
                                      </Box>
                                      <Box>
                                        <Typography variant="caption" color="text.secondary">
                                          New Price
                                        </Typography>
                                        <Typography variant="subtitle2" fontWeight={600}>
                                          {formatCurrency(event.new_price)}
                                        </Typography>
                                      </Box>
                                      <Box>
                                        <Typography variant="caption" color="text.secondary">
                                          Savings
                                        </Typography>
                                        <Typography variant="subtitle2" color="success.main" fontWeight={600}>
                                          {formatCurrency(event.price_difference)}
                                        </Typography>
                                      </Box>
                                    </Box>
                                  </Stack>
                                </Grid>
                              ))}
                          </Grid>
                        </Box>
                      </Paper>
                    ))}
                  </Stack>
                )}
            </Box>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">Upgrade Offers</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {(!reservationData.upgrade_offer_info?.upgrade_offer_history?.length && 
              !reservationData.upgrade_offer_info?.upgrade_alert_info?.length) ? (
                <Paper 
                  elevation={0} 
                  sx={{ 
                    p: 4,
                    textAlign: 'center',
                    border: `1px dashed ${theme.palette.divider}`,
                    borderRadius: 2,
                    bgcolor: alpha(theme.palette.primary.main, 0.02)
                  }}
                >
                  <Stack spacing={2} alignItems="center">
                    <Box sx={{ 
                      width: 48, 
                      height: 48, 
                      borderRadius: '50%',
                      bgcolor: alpha(theme.palette.primary.main, 0.1),
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}>
                      <FlightClassIcon color="primary" />
                    </Box>
                    <Typography variant="subtitle1" color="text.secondary">
                      No upgrade offers available yet
                    </Typography>
                    <Typography variant="body2" color="text.secondary" sx={{ maxWidth: 400 }}>
                      Initialize upgrade tracking to start monitoring available offers for your flight
                    </Typography>
                    <Box sx={{ mt: 2, display: 'flex', gap: 2 }}>
                      <Tooltip title={!reservationData.is_upcoming ? "Cannot initialize upgrade offers for past reservations" : ""}>
                        <span>
                          <Button
                            variant="contained"
                            color="primary"
                            startIcon={isInitializingUpgrades ? <CircularProgress size={20} color="inherit" /> : <AddIcon />}
                            onClick={handleInitializeUpgradeOffers}
                            disabled={isInitializingUpgrades || !reservationData.is_upcoming}
                            sx={{ 
                              borderRadius: '20px',
                              px: 3,
                              boxShadow: 'none',
                              bgcolor: '#535aff',
                              '&:hover': {
                                boxShadow: 'none',
                                bgcolor: alpha('#535aff', 0.8)
                              },
                              '&.Mui-disabled': {
                                bgcolor: theme.palette.action.disabledBackground,
                                color: theme.palette.action.disabled
                              }
                            }}
                          >
                            {isInitializingUpgrades ? 'Initializing...' : 'Initialize Upgrade Offers'}
                          </Button>
                        </span>
                      </Tooltip>
                      {reservationData.upgrade_offer_info?.upgradeable_cabins?.length > 0 && (
                        <Button
                          variant="outlined"
                          color="primary"
                          startIcon={<RefreshIcon />}
                          onClick={handleRefreshUpgradeOffers}
                          disabled={isRefreshingUpgrades}
                          sx={{ 
                            borderRadius: '20px',
                            px: 3,
                            borderColor: '#535aff',
                            color: '#535aff',
                            '&:hover': {
                              bgcolor: alpha('#535aff', 0.05),
                              borderColor: '#535aff'
                            }
                          }}
                        >
                          {isRefreshingUpgrades ? 'Checking...' : 'Check for Upgrades'}
                        </Button>
                      )}
                    </Box>
                    {upgradeActionStatus && (
                      <Paper
                        elevation={0}
                        sx={{
                          mt: 2,
                          p: 2,
                          bgcolor: alpha(
                            theme.palette[upgradeActionStatus.type === 'success' ? 'success' : 'error'].main, 
                            0.05
                          ),
                          border: `1px solid ${theme.palette[upgradeActionStatus.type === 'success' ? 'success' : 'error'].main}`,
                          borderRadius: 2,
                          maxWidth: 400
                        }}
                      >
                        <Stack direction="row" spacing={1} alignItems="center">
                          {upgradeActionStatus.type === 'success' ? (
                            <CheckCircleIcon sx={{ color: theme.palette.success.main }} fontSize="small" />
                          ) : (
                            <ErrorIcon sx={{ color: theme.palette.error.main }} fontSize="small" />
                          )}
                          <Typography variant="body2" color="text.secondary">
                            {upgradeActionStatus.message}
                          </Typography>
                        </Stack>
                      </Paper>
                    )}
                  </Stack>
                </Paper>
              ) : (
                <UpgradeOffersSection reservationData={reservationData} />
              )}
          </AccordionDetails>
        </Accordion>

        <Dialog
          open={openConfirmDialog}
          onClose={() => setOpenConfirmDialog(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Confirm Reservation Refresh"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              This action cannot be undone. Are you sure you want to refresh reservation {reservationData.reservation_id}?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenConfirmDialog(false)} color="primary">
              Cancel
            </Button>
            <Button 
              onClick={handleRefreshReservation} 
              color="primary" 
              autoFocus
              disabled={isRefreshingReservation}
            >
              {isRefreshingReservation ? <CircularProgress size={24} /> : 'Confirm'}
            </Button>
          </DialogActions>
        </Dialog>

        {renderTrackingDialog()}

        {refreshStatus && (
          <Box sx={{ position: 'fixed', top: 24, right: 24, zIndex: 9999 }}>
            <Alert 
              severity={refreshStatus === 'success' ? 'success' : 'error'}
              onClose={() => setRefreshStatus(null)}
            >
              {refreshStatus === 'success' 
                ? 'Reservation refreshed successfully!' 
                : 'Failed to refresh reservation. Please try again.'}
            </Alert>
          </Box>
        )}

        {upgradeActionStatus && (
          <Box sx={{ position: 'fixed', top: 24, right: 24, zIndex: 9999 }}>
            <Alert 
              severity={upgradeActionStatus.type}
              onClose={() => setUpgradeActionStatus(null)}
            >
              {upgradeActionStatus.message}
            </Alert>
          </Box>
        )}
      </Paper>
    </ThemeProvider>
  );
}

export default ReservationDisplayComponent;
